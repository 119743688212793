import { Confirm, DataBlock, DetailsList, Grid } from "components";
import Moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n, Translate } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
  upgradePlan,
} from "reducers/user";
import { connect } from "routes/routedComponent";
import { PrimaryButton } from "../../../../components";
import classes from "./../Setting.scss";
import ViewPlanFeatures from "./ViewPlanFeatures";

class Subscription extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      displayDelete: false,
      viewPlan: false,
      displayCreate: false,
    };
  }

  onCancelPlan = () => {
    const {
      select: { mobileAppPlans },
      user: { suit },
    } = this.props;
    let freePlans = mobileAppPlans.filter(
      (plan) => plan.price === 0 && plan.interval === "month"
    );
    if (freePlans && freePlans.length > 0) {
      let infra_type = freePlans[0].product.filter(
        (product) => product.name === "infra"
      )[0].limit[0].type;
      this.props
        .upgradePlan(suit.id, freePlans[0].id, infra_type, null, "paid")
        .then(() => {
          const {
            getCurrentSuit,
            user: { activeSuitId },
          } = this.props;
          getCurrentSuit(activeSuitId).then(() => {
            this.props.showNotification(
              {
                title: "",
                message: I18n.t("ProjectSettingPage_subscriptions_canceledMsg"),
                position: "bc",
                autoDismiss: 5,
              },
              "success"
            );
          });
        });
    }
  };

  onModalConfirm = () => {
    this.onCancelPlan();
    this.setState({ displayConfirm: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };
  generatAPI() {
    const {
      generateAPIKey,
      user: { suit, activeSuitId },
      getCurrentSuit,
    } = this.props;
    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };
    generateAPIKey(data, suit.id).then(() => {
      getCurrentSuit(activeSuitId);
    });
  }

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;

    fillDemoData(activeSuitId);

    this.handleCreateDismiss();
  };

  handleCreateDismiss = () => {
    this.setState({ displayCreate: false });
  };

  handleCreateShow = () => {
    this.setState({ displayCreate: true });
  };

  handleDeleteButton = () => {
    const {
      user: { activeSuitId },
      clearDemoData,
    } = this.props;

    clearDemoData(activeSuitId);

    this.handleDismiss();
  };

  handleDeleteShow = () => {
    this.context.router.push(`/app/${this.props.subdomain}/upgrade-plan`);
  };

  handleDismiss = () => {
    this.setState({ show: false, displayDelete: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleViewPlan = () => {
    this.context.router.push(`/app/${this.props.subdomain}/upgrade-plan`);
  };

  hideViewPlan = () => {
    this.setState({ viewPlan: false });
  };

  showViewPlan = () => {
    this.setState({ viewPlan: true });
  };

  render() {
    const {
      user: { suit, isPending },
    } = this.props;

    return (
      <Grid fluid>
        <ViewPlanFeatures
          onConfirm={this.handleViewPlan}
          onClose={this.hideViewPlan}
          display={suit && this.state.viewPlan}
          suit={suit}
          disabled={isPending || !this.props.user.suit.upgradable}
        />
        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={this.state.displayConfirm}
          title="ProjectSettingPage_subscriptions_cancel_title"
          description="ProjectSettingPage_subscriptions_cancelNote"
          btnText="ProjectSettingPage_subscriptions_cancel_title"
          btnType="critical"
          isPending={isPending}
        />
        {isPending && (
          <div className={classes["loader-container"]}>
            <div className={classes["loader"]}>
              <i className="fa fa-spinner" aria-hidden="true" />
            </div>
          </div>
        )}
        <div className={classes.new}>
          <DataBlock
            title="ProjectSettingPage_subscriptions_DataBlock_title"
            titleClass="bg-grey-lightest"
          >
            {suit ? (
              <div>
                <DetailsList
                  data={[
                    {
                      key: "ProjectSettingPage_subscriptions_DetailsList_1_title",
                      value: suit.plan_summary.plan_name || "NA",
                      description:
                        "ProjectSettingPage_subscriptions_DetailsList_1_description",
                      canCopy: false,
                    },
                    {
                      key: "ProjectSettingPage_subscriptions_DetailsList_2_title",
                      value:
                        Moment.utc(suit.subscription.subscription_start_date)
                          .local()
                          .format("Do MMMM YYYY, h:mm:ss a") || "NA",
                      description:
                        "ProjectSettingPage_subscriptions_DetailsList_2_description",
                      canCopy: false,
                    },
                    {
                      key: "ProjectSettingPage_subscriptions_DetailsList_3_title",
                      value: suit.plan_summary.plan_name
                        .toLocaleLowerCase()
                        .includes("ltd")
                        ? "LTD"
                        : Moment.utc(suit.subscription.subscription_end_date)
                            .local()
                            .format("Do MMMM YYYY, h:mm:ss a") || "NA",
                      description:
                        "ProjectSettingPage_subscriptions_DetailsList_3_description",
                      canCopy: false,
                    },
                    {
                      key: "ProjectSettingPage_subscriptions_DetailsList_4_title",
                      value: suit.subscription.status,
                      description:
                        "ProjectSettingPage_subscriptions_DetailsList_4_description",
                      canCopy: false,
                    },
                  ]}
                />
                <div style={{ display: "flex", marginLeft: "29px" }}>
                  {this.props.select.mobileAppPlans &&
                    this.props.select.mobileAppPlans.length > 0 && (
                      <PrimaryButton
                        onClick={() => this.setState({ displayConfirm: true })}
                      >
                        {<Translate value="cancelBtn" />}
                      </PrimaryButton>
                    )}

                  {this.props.user.suit && this.props.user.suit.upgradable && (
                    <PrimaryButton onClick={this.handleDeleteShow}>
                      {
                        <Translate value="ProjectSettingPage_subscriptions_upgradeBtn" />
                      }
                    </PrimaryButton>
                  )}
                  {this.props.user.suit && (
                    <PrimaryButton onClick={this.showViewPlan} disabled={!suit}>
                      {
                        <Translate value="ProjectSettingPage_subscriptions_planBtn" />
                      }
                    </PrimaryButton>
                  )}
                </div>
              </div>
            ) : null}
          </DataBlock>
        </div>
      </Grid>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
  }),
  {
    generateAPIKey,
    getCurrentSuit,
    getMobileMarketingPlans,
    getMobileAppPlans,
    fillDemoData,
    clearDemoData,
    upgradePlan,
    showNotification: Notifications.show,
  }
)(Subscription);
