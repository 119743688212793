import { i18nReducer as i18n } from "react-redux-i18n";
import { reducer as form } from "redux-form";
import appPush from "./AppPush";
import AppUser from "./AppUser";
import automator from "./Automator";
import CustomerSegment from "./CustomerSegment";
import DigitalInvoice from "./DigitalInvoice";
import EventDetails from "./EventDetails";
import eventsDefinition from "./EventsDefinition";
import Facebook from "./Facebook";
import FunnelsReducer from "./FunnelReducer";
import LastOrder from "./LastOrder";
import LeadForm from "./LeadForm";
import MediaLibrary from "./MediaLibrary";
import MicroPages from "./MicroPages";
import mobileAttributes from "./MobileAttributes";
import MobileUserInfo from "./MobileUserInfo";
import NotifyLogs from "./NotifyLogs";
import PaymentsReducer from "./Payments";
import reports from "./Reports";
import select from "./Select";
import StripPay from "./StripPay";
import uninstallMonitor from "./UninstallMonitor";
import UploadCsvAppUsers from "./UploadCsvAppUsers";
import userLogEvents from "./UserLogEvents";
import UserScreen from "./UserScreen";
import WhatsApp from "./WhatsApp";
import appboost from "./appboost";
import config from "./config";
import deeplink from "./deeplink";
import events from "./events";
import kpi from "./kpi";
import parse from "./parse";
import push from "./push";
import smartlink from "./smartlink";
import template from "./template";
import user from "./user";
import userTimline from "./userTimline";

const reducers = {
  user,
  smartlink,
  template,
  select,
  automator,
  form,
  i18n,
  config,
  NotifyLogs,
  parse,
  push,
  deeplink,
  CustomerSegment,
  LeadForm,
  StripPay,
  UserScreen,
  userTimline,
  LastOrder,
  DigitalInvoice,
  kpi,
  appboost,
  Facebook,
  AppUser,
  FunnelsReducer,
  uninstallMonitor,
  mobileAttributes,
  reports,
  UploadCsvAppUsers,
  appPush,
  events,
  EventDetails,
  userLogEvents,
  eventsDefinition,
  MobileUserInfo,
  MicroPages,
  WhatsApp,
  MediaLibrary,
  Payments: PaymentsReducer,
};
export default reducers;
