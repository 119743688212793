import { DATE_TYPE, NUMBER, STRING, URL } from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";
import DeepLinks from "../api/DeepLinks";

// ------------------------------------
// Constants
// ------------------------------------

const GET_SUIT_DEEPLINKS = "GET_SUIT_DEEPLINKS";
const GET_SUIT_DEEPLINK_DATA_TABLE = "GET_SUIT_DEEPLINK_DATA_TABLE";
const GET_CURRENT_DEEPLINK = "GET_CURRENT_DEEPLINK";
const DEEP_LINK_TOGGLE_ROW_ACTION_MENU = "DEEP_LINK_TOGGLE_ROW_ACTION_MENU";
const DEEP_LINK_CHECK_DATATABLE_ROW = "DEEP_LINK_CHECK_DATATABLE_ROW";
const DEEP_LINK_RESET_COLUMNS = "DEEP_LINK_RESET_COLUMNS";
const CREATE_DEEPLINK = "CREATE_DEEPLINK";
const UPDATE_DEEPLINK = "UPDATE_DEEPLINK";
const DELETE_DEEPLINK = "DELETE_DEEPLINK";
const REFERSHDATA = "REFERSHDATA";
const DEEPLINK_COLUMN_TOGGLE = "DEEPLINK_COLUMN_TOGGLE";
const SORT_CUSTOMER_DEEP_LINK = "SORT_CUSTOMER_DEEP_LINK";
const DEEP_LINK_CHECKALL_TABLE_ROWS = "DEEP_LINK_CHECKALL_TABLE_ROWS";
const DEEP_LINK_CLEARN_ACTION_MENU = "DEEP_LINK_CLEARN_ACTION_MENU";
const FILTERED_DEEPLINK_DATA_TABLE = "FILTERED_DEEPLINK_DATA_TABLE";
const GET_DEEPLINKS_COUNT = "GET_DEEPLINKS_COUNT";
const UPLOAD_BULK_DEEPLINK = "UPLOAD_BULK_DEEPLINK";

// ------------------------------------

// Actions
// ------------------------------------

export function toggleRowActionMenu(index) {
  return {
    type: DEEP_LINK_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function getSuitDeepLinks(serv, appid, master, limitVal, skipVal) {
  return {
    type: GET_SUIT_DEEPLINKS,
    payload: DeepLinks.getSuitDeepLinks(serv, appid, master, limitVal, skipVal),
  };
}

export function getDeepLinksCount(serv, appid, master) {
  return {
    type: GET_DEEPLINKS_COUNT,
    payload: DeepLinks.getDeepLinksCount(serv, appid, master),
  };
}

export function getSuitDeepLinksDataTable(
  serv,
  appid,
  master,
  limitVal,
  skipVal,
  data
) {
  if (data) {
    return {
      type: FILTERED_DEEPLINK_DATA_TABLE,
      payload: {
        res: DeepLinks.getSuitDeepLinks(serv, appid, master, limitVal, skipVal),
        data: data,
      },
    };
  } else {
    return {
      type: GET_SUIT_DEEPLINK_DATA_TABLE,
      payload: DeepLinks.getSuitDeepLinks(
        serv,
        appid,
        master,
        limitVal,
        skipVal
      ),
    };
  }
}

export function getCurrentDeepLink(DeepLinkId, serv, appid, master) {
  return {
    type: GET_CURRENT_DEEPLINK,
    payload: DeepLinks.getCurrentDeepLink(DeepLinkId, serv, appid, master),
  };
}

export function createDeepLink(data, serv, appid, master) {
  return {
    type: CREATE_DEEPLINK,
    payload: DeepLinks.createDeepLink(data, serv, appid, master),
  };
}

export function updateDeepLink(DeepLinkId, data, serv, appid, master) {
  return {
    type: UPDATE_DEEPLINK,
    payload: DeepLinks.updateDeepLink(DeepLinkId, data, serv, appid, master),
  };
}

export function deleteDeepLink(serv, id, appid, master) {
  return {
    type: DELETE_DEEPLINK,
    payload: DeepLinks.deleteDeepLink(serv, id, appid, master),
  };
}

export function refershData() {
  return {
    type: REFERSHDATA,
  };
}

export function onColumnToggle(idx) {
  return {
    type: DEEPLINK_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: DEEP_LINK_RESET_COLUMNS,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: DEEP_LINK_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER_DEEP_LINK,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function checkAllTableRows(newChecked) {
  return {
    type: DEEP_LINK_CHECKALL_TABLE_ROWS,
    payload: newChecked,
  };
}

export function clearActionMenu(idx) {
  return {
    type: DEEP_LINK_CLEARN_ACTION_MENU,
    payload: idx,
  };
}
export function uploadCsvDeepLink(suitId, data) {
  return {
    type: UPLOAD_BULK_DEEPLINK,
    payload: DeepLinks.uploadBulkDeepLink(suitId, data),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  deeplinks: null,
  deeplinksCount: 0,
  deeplink: null,
  filterData: [],
  isPending: false,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function deeplinkReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_SUIT_DEEPLINKS}_PENDING`:
    case `${GET_CURRENT_DEEPLINK}_PENDING`:
    case `${CREATE_DEEPLINK}_PENDING`:
    case `${UPDATE_DEEPLINK}_PENDING`:
    case `${DELETE_DEEPLINK}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_SUIT_DEEPLINKS}_REJECTED`:
    case `${GET_CURRENT_DEEPLINK}_REJECTED`:
    case `${CREATE_DEEPLINK}_REJECTED`:
    case `${UPDATE_DEEPLINK}_REJECTED`:
    case `${UPLOAD_BULK_DEEPLINK}_FULFILLED`:
    case `${DELETE_DEEPLINK}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${CREATE_DEEPLINK}_FULFILLED`:
    case `${UPDATE_DEEPLINK}_FULFILLED`:
    case `${DELETE_DEEPLINK}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_SUIT_DEEPLINKS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        deeplinks: action.payload,
      };
    case `${GET_DEEPLINKS_COUNT}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        deeplinksCount: action.payload.body.count,
      };
    case `${GET_CURRENT_DEEPLINK}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        deeplink: action.payload.body,
      };
    case `${REFERSHDATA}`: {
      return {
        deeplinks: null,
        deeplink: null,
        isPending: false,
        error: false,
      };
    }
    case DEEPLINK_COLUMN_TOGGLE:
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          labels: state.deeplinks.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case `${GET_SUIT_DEEPLINK_DATA_TABLE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        filterData: action.payload.body.results,
        deeplinks: prepareTableDate(action.payload.body.results),
      };
    case FILTERED_DEEPLINK_DATA_TABLE:
      return {
        ...state,
        isPending: false,
        deeplinks: prepareTableDate(
          _.filter(state.filterData, function (deeplink) {
            let from = Date.parse(
              moment(action.payload.data.start).format("YYYY-MM-DD HH:mm:ss")
            );
            let to = Date.parse(
              moment(action.payload.data.end)
                .add(23, "hours")
                .add(59, "minutes")
                .format("YYYY-MM-DD HH:mm:ss")
            );
            let current = Date.parse(moment(deeplink.createdAt));
            if (action.payload.data.name) {
              return (
                deeplink.name === action.payload.data.name &&
                current <= to &&
                current >= from
              );
            } else {
              return current <= to && current >= from;
            }
          })
        ),
        deeplinksCount: state.deeplinks.rows.length,
      };

    case DEEP_LINK_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          rows: state.deeplinks.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.deeplinks.rows[rowIdx],
                  actionsMenu: !state.deeplinks.rows[rowIdx].actionsMenu,
                }
              : state.deeplinks.rows[rowIdx];
          }),
        },
      };
    case DEEP_LINK_RESET_COLUMNS:
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          labels: state.deeplinks.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case DEEP_LINK_CHECK_DATATABLE_ROW:
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          rows: state.deeplinks.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.deeplinks.rows[rowIdx],
                  checked: !state.deeplinks.rows[rowIdx].checked,
                }
              : state.deeplinks.rows[rowIdx];
          }),
        },
      };
    case SORT_CUSTOMER_DEEP_LINK: {
      const indexOfKey = state.deeplinks.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);
      let tableRows = null;
      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.deeplinks.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.deeplinks.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.deeplinks.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          rows: tableRows,
        },
      };
    }
    case DEEP_LINK_CHECKALL_TABLE_ROWS:
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          rows: state.deeplinks.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case DEEP_LINK_CLEARN_ACTION_MENU:
      return {
        ...state,
        deeplinks: {
          ...state.deeplinks,
          rows: state.deeplinks.rows.map((row, idx) => {
            return idx === action.payload
              ? {
                  ...row,
                  actionsMenu: false,
                }
              : row;
          }),
        },
      };

    default:
      return state;
  }
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";
  const labels = [
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["name"],
    },
    {
      name: "URL",
      has_sort: false,
      selected: true,
      type: URL,
      varName: ["URL"],
    },
    {
      name: "DATE CREATED",
      has_sort: true,
      selected: true,
      type: DATE_TYPE,
      varName: ["createdAt"],
    },
  ];
  const rows = tableData.map((row) => ({
    checked: false,
    actionMenu: false,
    id: row.objectId,

    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
      moment(moment.utc(_.get(row, labels[2].varName, labels[2].defaultValue)))
        .local()
        .format(dateFormat),
    ],
  }));
  let byDefaultSortedRows = _.sortBy(rows, (row) =>
    new Date(row.values[2]).getTime()
  );
  _.reverse(byDefaultSortedRows);
  return {
    labels,
    rows: byDefaultSortedRows,
    actions: [{ type: "delete" }],
  };
}
