import React from "react";
import { AiOutlineLock, AiOutlineSetting } from "react-icons/ai";
import { BiStats } from "react-icons/bi";
import { BsCameraVideo, BsGrid, BsPeople } from "react-icons/bs";
import { FaBullhorn } from "react-icons/fa";
import { IoIosStats } from "react-icons/io";
import { MdLoyalty } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { I18n } from "react-redux-i18n";
import productsNames from "../../consts/productsNames";
import { hasProduct, isSubUser } from "../../modules/user";
import classes from "./Sidebar.module.scss";

const RETAIL_GAIN_SIDEBAR_ITEMS = (subdomain, activeSuitId) => [
  {
    key: "1",
    title: <span className={classes.sidebarItemLabel}>Store Dashboard</span>,
    Icon: () => <BsGrid className={classes.sidebarItemIcon} />,
    link: `/app/${subdomain}`,
  },
  {
    key: "2",
    title: (
      <span className={classes.sidebarItemLabel}>Point of Sale (POS)</span>
    ),
    Icon: () => <RiMoneyDollarCircleLine className={classes.sidebarItemIcon} />,
    children: [
      {
        key: "2-1",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("Invoices")}
          </span>
        ),
        link: `/app/${subdomain}/digitalinvoices`,
      },
      {
        key: "2-2",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("Products")}
          </span>
        ),
        link: `/app/${subdomain}/products`,
      },
      {
        key: "2-3",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("ReportsPage_title2")}
          </span>
        ),
        link: `/app/${subdomain}/reports`,
      },
    ],
  },
  {
    key: "3",
    title: (
      <span className={classes.sidebarItemLabel}>{I18n.t("MarketingCRM")}</span>
    ),
    Icon: () => <BiStats className={classes.sidebarItemIcon} />,
    children: [
      {
        key: "3-1",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("newUsersScreen_title2")}
          </span>
        ),
        link: `/app/${subdomain}/mobileusers`,
      },
      {
        key: "3-4",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("retail segments")}
          </span>
        ),
        link: `/app/${subdomain}/retailSegments`,
      },
      {
        key: "3-2",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("campaigns")}
          </span>
        ),
        children: [
          {
            key: "3-2-1",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("summary")}
              </span>
            ),
            link: `/app/${subdomain}/campaignssummary`,
          },
          {
            key: "3-2-2",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("NewCampaingsPage_title2")}
              </span>
            ),
            link: `/app/${subdomain}/newcampaigns`,
          },
          {
            key: "3-2-3",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("Automated")}
              </span>
            ),
            link: `/app/${subdomain}/automator`,
          },
          {
            key: "3-2-4",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("Templates")}
              </span>
            ),
            link: `/app/${subdomain}/template`,
          },
        ],
      },
      {
        key: "3-5",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("contact list")}
          </span>
        ),
        link: `/app/${subdomain}/smstargetlists`,
      },
      {
        key: "3-6",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("WebPushSubscribers")}
          </span>
        ),
        link: `/app/${subdomain}/push/web/subscribers`,
      },
      {
        key: "3-3",
        title: (
          <span className={classes.sidebarSubItemLabel}>Marketing Links</span>
        ),
        children: [
          {
            key: "3-3-1",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("Smartdeeplinks")}
              </span>
            ),
            link: `/app/${subdomain}/smartDeepLinks`,
          },
          {
            key: "3-3-2",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("MicroPages")}
              </span>
            ),
            link: `/app/${subdomain}/microPages`,
          },
        ],
      },
    ],
  },
  {
    key: "6",
    title: (
      <span className={classes.sidebarItemLabel}>{I18n.t("loyalty")}</span>
    ),
    Icon: () => <MdLoyalty className={classes.sidebarItemIcon} />,
    children: [
      ...(hasProduct(activeSuitId, productsNames.loyalty)
        ? [
            {
              key: "6-1",
              title: (
                <span className={classes.sidebarSubItemLabel}>
                  {I18n.t("coupon")}
                </span>
              ),
              link: `/app/${subdomain}/coupons`,
            },
          ]
        : [
            {
              key: "6-1",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("coupon")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/coupons`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.loyalty)
        ? [
            {
              key: "6-2",
              title: (
                <span className={classes.sidebarSubItemLabel}>
                  {I18n.t("points")}
                </span>
              ),
              link: `/app/${subdomain}/points`,
            },
          ]
        : [
            {
              key: "6-2",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("points")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/points`,
            },
          ]),
    ],
  },
  {
    key: "4",
    title: (
      <span className={classes.sidebarItemLabel}>{I18n.t("tutorials")}</span>
    ),
    Icon: () => <BsCameraVideo className={classes.sidebarItemIcon} />,
    link: `/app/${subdomain}/tutorials`,
  },
  ...(!isSubUser()
    ? [
        {
          key: "5",
          title: (
            <span className={classes.sidebarItemLabel}>
              {I18n.t("settings")}
            </span>
          ),
          Icon: () => <AiOutlineSetting className={classes.sidebarItemIcon} />,
          link: `/app/${subdomain}/settings`,
        },
      ]
    : []),
];

const MOBILE_APP_SIDEBAR_ITEMS = (subdomain, activeSuitId) => [
  {
    key: "1",
    title: "App Dashboard",
    Icon: () => <BsGrid className={classes.sidebarItemIcon} />,
    link: `/app/${subdomain}`,
  },
  {
    key: "2",
    title: I18n.t("MarketingCRM"),
    Icon: () => <BsPeople className={classes.sidebarItemIcon} />,
    children: [
      {
        key: "2-1",
        title: I18n.t("RichUserProfile"),
        link: `/app/${subdomain}/RichUserProfile`,
      },
      ...(hasProduct(activeSuitId, productsNames.segments)
        ? [
            {
              key: "2-2",
              title: I18n.t("Segments"),
              link: `/app/${subdomain}/CustomerSegments/ListCustomerSegments`,
            },
          ]
        : [
            {
              key: "2-2",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("Segments")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/CustomerSegments/ListCustomerSegments`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.funnels)
        ? [
            {
              key: "2-3",
              title: I18n.t("Funnels"),
              link: `/app/${subdomain}/Funnel`,
            },
          ]
        : [
            {
              key: "2-3",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("Funnels")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/Funnel`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.events)
        ? [
            {
              key: "2-4",
              title: I18n.t("contact list"),
              link: `/app/${subdomain}/smstargetlists`,
            },
          ]
        : [
            {
              key: "2-4",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("contact list")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/smstargetlists`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.events)
        ? [
            {
              key: "2-5",
              title: I18n.t("Events"),
              link: `/app/${subdomain}/events`,
            },
            {
              key: "2-6",
              title: I18n.t("EventsDefinition"),
              link: `/app/${subdomain}/eventsDefinition`,
            },
          ]
        : [
            {
              key: "2-5",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <span>{I18n.t("Events")}</span>
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/events`,
            },
            {
              key: "2-6",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <span>{I18n.t("EventsDefinition")}</span>
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/eventsDefinition`,
            },
          ]),
    ],
  },
  {
    key: "3",
    title: I18n.t("Analytics"),
    Icon: () => <IoIosStats className={classes.sidebarItemIcon} />,
    children: [
      ...(hasProduct(activeSuitId, productsNames.mobileAttribution)
        ? [
            {
              key: "3-1",
              title: I18n.t("Acquisition"),
              link: `/app/${subdomain}/mobileAttributes`,
            },
          ]
        : [
            {
              key: "3-1",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("Acquisition")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/mobileAttributes`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.uninstallMonitor)
        ? [
            {
              key: "3-2",
              title: I18n.t("UninstallMonitor"),
              link: `/app/${subdomain}/UninstallMonitor`,
            },
          ]
        : [
            {
              key: "3-2",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("UninstallMonitor")}
                  <AiOutlineLock className={classes.sidebarItemIcon} />
                </span>
              ),
              link: `/app/${subdomain}/UninstallMonitor`,
            },
          ]),
      {
        key: "3-3",
        title: I18n.t("Revenue"),
        link: `/app/${subdomain}/RevenueTransactions`,
      },
    ],
  },
  {
    key: "4",
    title: I18n.t("campaigns"),
    Icon: () => <FaBullhorn className={classes.sidebarItemIcon} />,
    children: [
      {
        key: "4-1",
        title: I18n.t("summary"),
        link: `/app/${subdomain}/campaignssummary`,
      },
      {
        key: "4-2",
        title: I18n.t("NewCampaingsPage_title2"),
        link: `/app/${subdomain}/newcampaigns`,
      },
      ...(hasProduct(activeSuitId, productsNames.automator)
        ? [
            {
              key: "4-3",
              title: I18n.t("Automated"),
              link: `/app/${subdomain}/automator`,
            },
          ]
        : [
            {
              key: "4-3",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("Automated")}
                  <AiOutlineLock
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                    }}
                  />
                </span>
              ),
              link: `/app/${subdomain}/automator`,
            },
          ]),
      {
        key: "4-4",
        title: I18n.t("Templates"),
        link: `/app/${subdomain}/template`,
      },
      ...(hasProduct(activeSuitId, productsNames.smartDeepLink)
        ? [
            {
              key: "4-5",
              title: I18n.t("Smartdeeplinks"),
              link: `/app/${subdomain}/smartDeepLinks`,
            },
          ]
        : [
            {
              key: "4-5",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("Smartdeeplinks")}
                  <AiOutlineLock
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                    }}
                  />
                </span>
              ),
              link: `/app/${subdomain}/smartDeepLinks`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.mobileLandingPages)
        ? [
            {
              key: "4-6",
              title: I18n.t("MicroPages"),
              link: `/app/${subdomain}/microPages`,
            },
          ]
        : [
            {
              key: "4-6",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("MicroPages")}
                  <AiOutlineLock
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                    }}
                  />
                </span>
              ),
              link: `/app/${subdomain}/microPages`,
            },
          ]),
      ...(hasProduct(activeSuitId, productsNames.webPushNotification)
        ? [
            {
              key: "4-7",
              title: I18n.t("WebPushSubscribers"),
              link: `/app/${subdomain}/push/web/subscribers`,
            },
          ]
        : [
            {
              key: "4-7",
              title: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {I18n.t("WebPushSubscribers")}
                  <AiOutlineLock
                    style={{
                      width: "24px",
                      height: "24px",
                      flexShrink: 0,
                    }}
                  />
                </span>
              ),
              link: `/app/${subdomain}/push/web/subscribers`,
            },
          ]),
      {
        key: "4-8",
        title: I18n.t("NotificationLogs"),
        link: `/app/${subdomain}/notificationLogs`,
      },
    ],
  },
  {
    key: "5",
    title: I18n.t("tutorials"),
    Icon: () => <BsCameraVideo className={classes.sidebarItemIcon} />,
    link: `/app/${subdomain}/tutorials`,
  },
  ...(!isSubUser()
    ? [
        {
          key: "6",
          title: I18n.t("settings"),
          link: `/app/${subdomain}/settings`,
          Icon: () => <AiOutlineSetting className={classes.sidebarItemIcon} />,
        },
      ]
    : []),
];

const CHARITY_SIDEBAR_ITEMS = (subdomain, activeSuitId) => [
  {
    key: "1",
    title: (
      <span className={classes.sidebarItemLabel}>{I18n.t("dashboard")}</span>
    ),
    Icon: () => <BsGrid className={classes.sidebarItemIcon} />,
    link: `/app/${subdomain}`,
  },
  {
    key: "3",
    title: (
      <span className={classes.sidebarItemLabel}>{I18n.t("marketing")}</span>
    ),
    Icon: () => <BiStats className={classes.sidebarItemIcon} />,
    children: [
      {
        key: "3-2",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("campaigns")}
          </span>
        ),
        children: [
          {
            key: "3-2-1",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("summary")}
              </span>
            ),
            link: `/app/${subdomain}/campaignssummary`,
          },
          {
            key: "3-2-2",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("NewCampaingsPage_title2")}
              </span>
            ),
            link: `/app/${subdomain}/newcampaigns`,
          },
          {
            key: "3-2-4",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("Templates")}
              </span>
            ),
            link: `/app/${subdomain}/template`,
          },
        ],
      },
      {
        key: "3-5",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("contact list")}
          </span>
        ),
        link: `/app/${subdomain}/smstargetlists`,
      },
      {
        key: "3-6",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("WebPushSubscribers")}
          </span>
        ),
        link: `/app/${subdomain}/push/web/subscribers`,
      },
      {
        key: "3-3",
        title: (
          <span className={classes.sidebarSubItemLabel}>
            {I18n.t("marketingLinks")}
          </span>
        ),
        children: [
          {
            key: "3-3-1",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("Smartdeeplinks")}
              </span>
            ),
            link: `/app/${subdomain}/smartDeepLinks`,
          },
          {
            key: "3-3-2",
            title: (
              <span className={classes.sidebarSubItemLabel}>
                {I18n.t("MicroPages")}
              </span>
            ),
            link: `/app/${subdomain}/microPages`,
          },
        ],
      },
    ],
  },
  {
    key: "4",
    title: (
      <span className={classes.sidebarItemLabel}>{I18n.t("tutorials")}</span>
    ),
    Icon: () => <BsCameraVideo className={classes.sidebarItemIcon} />,
    link: `/app/${subdomain}/tutorials`,
  },
  ...(!isSubUser()
    ? [
        {
          key: "5",
          title: (
            <span className={classes.sidebarItemLabel}>
              {I18n.t("settings")}
            </span>
          ),
          Icon: () => <AiOutlineSetting className={classes.sidebarItemIcon} />,
          link: `/app/${subdomain}/settings`,
        },
      ]
    : []),
];

export {
  CHARITY_SIDEBAR_ITEMS,
  MOBILE_APP_SIDEBAR_ITEMS,
  RETAIL_GAIN_SIDEBAR_ITEMS,
};
