import { Col, Form, Row, TextBox } from "components";
import { required } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";
import PrimaryButton from "../../../../../../components/Form/Buttons/PrimaryButton/PrimaryButton";
import classes from "../WhatsappConfig.scss";

export class TwilioWhatsapp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { notify } = nextProps.config;
    if (
      notify &&
      notify.channels.whatsapp &&
      notify.channels.whatsapp.connection_info.type === "WHATSAPP-TWILIO" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        vendor: notify.channels.whatsapp.vendor,
        type: "WHATSAPP-TWILIO",
        account_sid:
          notify.channels.whatsapp.connection_info.account_sid || undefined,
        sender: notify.channels.whatsapp.connection_info.sender || undefined,
        auth_token:
          notify.channels.whatsapp.connection_info.auth_token || undefined,
      });
      this.setState({ initialized: true });
    }
  }
  render() {
    const { valid, handleSubmit, disabled } = this.props;

    return (
      <Form>
        <div className={classes.contain}>
          <Row>
            <Col md={6}>
              <Field
                name="vendor"
                placeholder={I18n.t("PlatformsPage_vendor_placeholder")}
                validate={required}
                label={I18n.t("name")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <Field
                name="account_sid"
                placeholder={I18n.t("PlatformsPage_AccountSid_placeholder")}
                validate={required}
                label={I18n.t("PlatformsPage_AccountSid_label")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="sender"
                placeholder={I18n.t("PlatformsPage_sender_placeholder")}
                validate={required}
                label={I18n.t("PlatformsPage_sender_label")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <Field
                name="auth_token"
                placeholder={I18n.t("PlatformsPage_Authtoken_placeholder")}
                validate={required}
                label={I18n.t("PlatformsPage_Authtoken_label")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>

          <PrimaryButton onClick={handleSubmit} disabled={!valid}>
            {I18n.t("saveChangesBtn")}
          </PrimaryButton>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      getNotifyConfig,
    }
  )(TwilioWhatsapp)
);
