import React, { Component } from "react";
import ReactDOM from "react-dom";
import classes from "./Menu.scss";

class Menu extends Component {
  componentWillMount() {
    // add event listener for clicks
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e, element) => {
    // this is the key part - ReactDOM.findDOMNode(this) gives you a reference
    // to your CalendarPopup component;
    // e.target is the element which was clicked upon.
    // check whether the element clicked upon is in your component - if not,
    // then call the close logic
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      // the click was outside your component, so handle closing here
      this.props.clearMenu();
      return;
    }
    this.props.onFilterOptionSelect(element);
  };

  render() {
    const index = this.props.index;
    return (
      <div className={classes["div-filter-category"]}>
        <div>
          {this.props.data.map((element, idx) => (
            <div
              key={idx}
              onClick={(e) => this.handleClick(e, element.values[index])}
            >
              {element.values[index]}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Menu;
