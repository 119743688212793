import { Col, Form, Row, TextBox } from "components";
import { required } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";
import PrimaryButton from "../../../../../../components/Form/Buttons/PrimaryButton/PrimaryButton";
import classes from "../EmailConfig.scss";

export class SendGrid extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { notify } = nextProps.config;
    if (
      notify &&
      notify.channels.email.connection_info.type === "SEND-GRID" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        vendor: notify.channels.email.vendor,
        type: "SEND-GRID" || undefined,
        from_email:
          notify.channels.email.connection_info.from_email || undefined,
        api_key: notify.channels.email.connection_info.api_key || undefined,
      });
      this.setState({
        initialized: true,
      });
    }
  }

  render() {
    const { valid, handleSubmit, disabled } = this.props;

    return (
      <Form>
        <div className={classes.contain}>
          <Row>
            <Col md={6}>
              <Field
                name="vendor"
                placeholder={I18n.t("PlatformsPage_vendor_placeholder")}
                validate={required}
                label={I18n.t("name")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <Field
                name="from_email"
                placeholder={I18n.t("PlatformsPage_email_placeholder")}
                validate={required}
                label={I18n.t("PlatformsPage_email_label")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="api_key"
                placeholder={I18n.t("APIKey_placeholder")}
                validate={required}
                label={I18n.t("APIKey")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Col md={12}>
            <Row>
              <div
                style={{
                  alignItems: "left",
                  textAlign: "left",
                  justifyContent: "left",
                }}
              >
                <PrimaryButton onClick={handleSubmit} disabled={!valid}>
                  {I18n.t("saveChangesBtn")}
                </PrimaryButton>
              </div>
            </Row>
          </Col>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      //
      getNotifyConfig,
    }
  )(SendGrid)
);
