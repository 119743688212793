const RETAIL_GAIN = "retail_gain";
const MOBILE_APP = "mobile_app";
const CHARITY_APP = "charity_app";

export { CHARITY_APP, MOBILE_APP, RETAIL_GAIN };

export const suitTypesMap = {
  [RETAIL_GAIN]: "Retail Gain",
  [MOBILE_APP]: "Mobile App",
  [CHARITY_APP]: "Charity",
};
