import Funnels from "api/Funnels";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";

const GET_FUNNELS = "GET_FUNNELS";
const GET_FUNNEL = "GET_FUNNEL";
const CREATE_FUNNELS = "CREATE_FUNNELS";
const FUNNELS_TOGGLE_ROW_ACTION_MENU = "FUNNELS_TOGGLE_ROW_ACTION_MENU";
const FUNNELS_CLEAR_ACTION_MENU = "FUNNELS_CLEAR_ACTION_MENU";
const DELETE_FUNNEL = "DELETE_FUNNEL";
const UPDATE_FUNNELS = "UPDATE_FUNNELS";
const SORT_FUNNELS = "SORT_FUNNELS";
const FUNNELS_ON_COLUMN_TOGGLE = "FUNNELS_ON_COLUMN_TOGGLE";
const FUNNELS_CHECK_ALL_DATA_TABLE_ROWS = "FUNNELS_CHECK_ALL_DATA_TABLE_ROWS";
const FUNNELS_CHECK_DATA_TABLE_ROW = "FUNNELS_CHECK_DATA_TABLE_ROW";
const DELETE_FUNNEL_BULK = "DELETE_FUNNEL_BULK";

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  tableData: null,
  isPending: false,
  error: false,
  authPending: false,
  deletePending: false,
  objects: [],
  detail: null,
};

// / ------------------------------------
// Actions
// ------------------------------------

export function updateFunnels(suitId, apiKey) {
  return {
    type: UPDATE_FUNNELS,
    payload: Funnels.updateFunnels(suitId, apiKey),
  };
}

export function getFunnels(serv, appid, master) {
  return {
    type: GET_FUNNELS,
    payload: Funnels.getFunnels(serv, appid, master),
  };
}

export function getFunnel(appId, apiKey, funnelId, date) {
  return {
    type: GET_FUNNEL,
    payload: Funnels.getFunnel(appId, apiKey, funnelId, date),
  };
}

export function deleteFunnel(suitId, apiKey, funnelId) {
  return {
    type: DELETE_FUNNEL,
    payload: Funnels.deleteFunnel(suitId, apiKey, funnelId),
  };
}

// funnelsIds = {"funnels_ids":["test","test"]}
export function deleteFunnelBulk(suitId, funnelsIds) {
  return {
    type: DELETE_FUNNEL_BULK,
    payload: Funnels.deleteFunnelBulk(suitId, funnelsIds),
  };
}

export function createFunnel(serv, appid, master, data) {
  return {
    type: CREATE_FUNNELS,
    payload: Funnels.createFunnel(serv, appid, master, data),
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: FUNNELS_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: FUNNELS_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_FUNNELS,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function onColumnToggle(idx) {
  return {
    type: FUNNELS_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: FUNNELS_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: FUNNELS_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}

// ------------------------------------
// Reducer
// ------------------------------------

export default function FunnelsReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_FUNNELS}_PENDING`:
    case `${UPDATE_FUNNELS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${GET_FUNNELS}_REJECTED`:
    case `${UPDATE_FUNNELS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    case `${GET_FUNNELS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        tableData: prepareFunnelsTableData(action.payload.body.results),
        objects: action.payload.body.results,
      };
    case `${DELETE_FUNNEL}_FULFILLED`:
    case `${DELETE_FUNNEL_BULK}_FULFILLED`:
      return {
        ...state,
        deletePending: false,
        tableData: null,
      };
    case `${UPDATE_FUNNELS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case `${GET_FUNNEL}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${DELETE_FUNNEL}_PENDING`:
    case `${DELETE_FUNNEL_BULK}_PENDING`:
      return {
        ...state,
        deletePending: true,
        error: false,
      };

    case `${DELETE_FUNNEL}_REJECTED`:
    case `${DELETE_FUNNEL_BULK}_REJECTED`:
      return {
        ...state,
        deletePending: false,
        error: true,
      };

    case `${GET_FUNNEL}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    case `${GET_FUNNEL}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        detail: action.payload.body.result,
      };

    case `${CREATE_FUNNELS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${CREATE_FUNNELS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    case `${CREATE_FUNNELS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case FUNNELS_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };
    case FUNNELS_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, actionsMenu: !row.actionsMenu }
              : row;
          }),
        },
      };

    case SORT_FUNNELS: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }

    case FUNNELS_ON_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case FUNNELS_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case FUNNELS_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? { ...row, checked: !row.checked }
              : row;
          }),
        },
      };

    default:
      return state;
  }
}

const dateFormat = "DD MMM YY HH:mm:ss";

const prepareFunnelsTableData = (tableData) => {
  const labels = [
    {
      name: "ID",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "objectId",
      defaultValue: "N/A",
    },
    {
      name: "NAME",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "N/A",
    },
    {
      name: "DESCRIPTION",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "description",
      defaultValue: "N/A",
    },
    {
      name: "CREATED AT",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "createdAt",
      defaultValue: "N/A",
    },
    {
      name: "UPDATED AT",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => {
    return {
      checked: false,
      id: _.get(row, "objectId", ""),
      actionsMenu: false,
      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows, actions: true };
};
