import api from "modules/api";
import EP from "consts/api";

export default class Funnels {
  static getFunnels = (serv, appid, master) => {
    return api("get", EP.FUNNELS.ALL(serv), false, false, false)
      .set("Content-Type", "application/json")
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };
  static createFunnel = (serv, appid, master, data) => {
    return api("post", EP.FUNNELS.CREATE(serv), data, false, false)
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");
  };
  static getFunnel = (appId, apiKey, funnelId, date) => {
    return api(
      "get",
      EP.FUNNELS.SINGLE(appId, funnelId, date),
      false,
      false,
      false
    )
      .set("appApiKey", `${apiKey}`)
      .set("Content-Type", "application/json");
  };

  static deleteFunnel = (suitId, apiKey, funnelId) =>
    api("delete", EP.FUNNELS.DELETE(suitId, funnelId)).set("appApiKey", apiKey);

  static deleteFunnelBulk = (suitId, funnelsIds) =>
    api("delete", EP.FUNNELS.BULK(suitId), funnelsIds);

  static updateFunnels = (suitId, apiKey) =>
    api("PUT", EP.FUNNELS.UPDATE_FUNNELS(suitId)).set("appApiKey", apiKey);
}
