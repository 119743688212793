import UninstallMonitor from "api/UninstallMonitor";
import Notifications from "react-notification-system-redux"; // used to appear toast message when request success

export const LOAD_SCREEN_ALERT = "LOAD_SCREEN_ALERT";
export const GET_UNINSTALL_MONITOR_DATA_REJECTED =
  "GET_UNINSTALL_MONITOR_DATA_REJECTED";
export const GET_UNINSTALL_MONITOR_DATA_FULFILLED =
  "GET_UNINSTALL_MONITOR_DATA_FULFILLED";
export const GET_UNINSTALL_MONITOR_DATA_PENDING =
  "GET_UNINSTALL_MONITOR_DATA_PENDING";
export const FOLLOW_UP_USERS_REJECTED = "FOLLOW_UP_USERS_REJECTED";
export const FOLLOW_UP_USERS_FULLFILLED = "FOLLOW_UP_USERS_FULLFILLED";
export const CLEAN_UP_USERS_FULLFILLED = "CLEAN_UP_USERS_FULLFILLED";
export const CLEAN_UP_USERS_REJECTED = "CLEAN_UP_USERS_REJECTED";
export const UNINSTALL_MONITOR_BEGIN_AUTH = "UNINSTALL_MONITOR_BEGIN_AUTH";
export const UNINSTALL_MONITOR_CLEAR_AUTH = "UNINSTALL_MONITOR_CLEAR_AUTH";
export const UNINSTALL_MONITOR_UNMOUNT = "UNINSTALL_MONITOR_UNMOUNT";

export function loadScreenAlert() {
  return {
    type: LOAD_SCREEN_ALERT,
  };
}

const prepareData = (response) => {
  const months = [];
  const android = [];
  const ios = [];
  response.uninstallAndroidAnalytics.forEach((element) => {
    months.push(element.date);
    android.push(element.count);
  });
  response.uninstallIOSAnalytics.forEach((element) => {
    ios.push(element.count);
  });
  return months.map((element, idx) => ({
    date: element,
    android: android[idx],
    ios: ios[idx],
  }));
};

export const getFilteredData =
  (serverURL, appId, suitId, masterKey, apiKey, start, end) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_UNINSTALL_MONITOR_DATA_PENDING });
      const response = await UninstallMonitor.getData(
        serverURL,
        appId,
        suitId,
        masterKey,
        apiKey,
        start,
        end
      );
      const data = prepareData(response.body.result);
      dispatch({
        type: GET_UNINSTALL_MONITOR_DATA_FULFILLED,
        payload: { data, total: response.body.result.total },
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_UNINSTALL_MONITOR_DATA_REJECTED, payload: error });
    }
  };

export const followUpUsers =
  (serverURL, appId, suitId, masterKey, apiKey, start, end) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_UNINSTALL_MONITOR_DATA_PENDING });
      const requestRes = await UninstallMonitor.followUpUsers(
        // await UninstallMonitor.followUpUsers(
        serverURL,
        appId,
        suitId,
        masterKey,
        apiKey,
        start,
        end
      );

      // used to appear toast message when request success
      if (
        requestRes.body.result.status === "success" ||
        requestRes.body.result.status === "sucess"
      ) {
        const notificationOpts = {
          title: "",
          message: "successful send",
          position: "bc",
          autoDismiss: 5,
        };
        dispatch(Notifications.success(notificationOpts));
      }

      dispatch({ type: FOLLOW_UP_USERS_FULLFILLED });
    } catch (err) {
      dispatch({ type: FOLLOW_UP_USERS_REJECTED, payload: err });
    }
  };

export const cleanUpUsers =
  (serverURL, appId, suitId, masterKey, apiKey, email, start, end) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_UNINSTALL_MONITOR_DATA_PENDING });
      await UninstallMonitor.cleanUpUsers(
        serverURL,
        appId,
        suitId,
        masterKey,
        apiKey,
        email
      );
      const response = await UninstallMonitor.getData(
        serverURL,
        appId,
        suitId,
        masterKey,
        apiKey,
        start,
        end
      );
      const data = prepareData(response.body.result);
      dispatch({ type: CLEAN_UP_USERS_FULLFILLED });
      dispatch({
        type: GET_UNINSTALL_MONITOR_DATA_FULFILLED,
        payload: { data, total: response.body.result.total },
      });
    } catch (err) {
      dispatch({ type: CLEAN_UP_USERS_REJECTED, payload: err });
    }
  };

export const beginAuthPending = () => {
  return {
    type: UNINSTALL_MONITOR_BEGIN_AUTH,
  };
};

export const clearAuthPending = () => {
  return {
    type: UNINSTALL_MONITOR_CLEAR_AUTH,
  };
};

export const unmountUninstallMonitor = () => {
  return {
    type: UNINSTALL_MONITOR_UNMOUNT,
  };
};

const initialState = {
  result: null,
  isPending: false,
  total: null,
  authPending: false,
};

export default function UninstallMonitorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_UNINSTALL_MONITOR_DATA_PENDING:
      return {
        ...state,
        isPending: true,
      };

    case UNINSTALL_MONITOR_UNMOUNT:
      return {
        ...initialState,
      };

    case UNINSTALL_MONITOR_BEGIN_AUTH:
      return {
        ...state,
        authPending: true,
      };

    case UNINSTALL_MONITOR_CLEAR_AUTH:
      return {
        ...state,
        authPending: false,
      };

    case GET_UNINSTALL_MONITOR_DATA_REJECTED:
    case FOLLOW_UP_USERS_REJECTED:
    case CLEAN_UP_USERS_REJECTED:
      return {
        ...state,
        isPending: false,
        error: true,
      };

    case GET_UNINSTALL_MONITOR_DATA_FULFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
        result: action.payload.data,
        total: action.payload.total,
      };

    case FOLLOW_UP_USERS_FULLFILLED:
    case CLEAN_UP_USERS_FULLFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
      };
    default:
      return state;
  }
}
