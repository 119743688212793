import { Switch } from "antd";
import { Col, Confirm, DataBlock, Form, Row, TextBox } from "components";
import { trimm } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import {
  advancedConfig,
  getAppBackendConfig,
  getCurrentSuit,
} from "reducers/user";
import { Field, reduxForm } from "redux-form";
import { PrimaryButton } from "../../../../../components";
import { Toast } from "../../../../../modules/toast";
import classes from "../Setting.scss";

export class ConfigAdvanced extends React.Component {
  state = {
    allowClient: false,
    enableAnonymous: false,
    showTool: "",
    displayConfirm: false,
  };
  componentDidMount() {
    let userSuits = JSON.parse(localStorage.getItem("user.suits"));
    const {
      user: { activeSuitId, suits },
    } = this.props;
    let currentSuit = userSuits.filter(
      (suit) =>
        suit.subdomain === window.location.pathname.split("/")[2].toLowerCase()
    )[0];
    this.props.getCurrentSuit(currentSuit.id).then(async (value) => {
      const response = await value.action.payload;
      let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
      const appbackend_userId = SuitArr[0].appbackend_userId;
      return this.props.getAppBackendConfig(
        response.body.subdomain,
        appbackend_userId
      );
    });
  }

  componentWillReceiveProps(nextProps) {
    const { appbackend } = nextProps.user;
    if (appbackend && !this.state.initialized) {
      nextProps.initialize({
        allowClientClassCreation:
          appbackend.allowClientClassCreation || undefined,
        enableAnonymousUsers: appbackend.enableAnonymousUsers || undefined,
        passwordPolicy: appbackend.passwordPolicy || undefined,
        auth: appbackend.auth || undefined,
        filesAdapter: appbackend.filesAdapter || undefined,
        loggerAdapter: appbackend.loggerAdapter || undefined,
        maxUploadSize: appbackend.maxUploadSize || undefined,
        logLevel: appbackend.logLevel || undefined,
        sessionLength: appbackend.sessionLength || undefined,
        maxLimit: appbackend.maxLimit || undefined,
        revokeSessionOnPasswordReset:
          appbackend.revokeSessionOnPasswordReset || undefined,
        accountLockout: appbackend.accountLockout || undefined,
        customPages: appbackend.customPages || undefined,
        middleware: appbackend.middleware || undefined,
        masterKeyIps: appbackend.masterKeyIps || undefined,
        readOnlyMasterKey: appbackend.readOnlyMasterKey || undefined,
      });
      if (appbackend && appbackend.allowClientClassCreation) {
        this.setState({ allowClient: true });
      }
      if (appbackend && appbackend.enableAnonymousUsers) {
        this.setState({ enableAnonymous: true });
      }
      this.setState({ initialized: true });
    }
  }

  onModalConfirm = () => {
    const {
      advancedConfig,
      getAppBackendConfig,
      form,
      user: { activeSuitId, data, suits },
    } = this.props;
    const project = window.location.href.split("/")[4];
    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    let values = form.configadvanced.values;
    advancedConfig(
      data.user_id,
      activeSuitId,
      { ...values, subdomain: project },
      appbackend_userId
    ).then(() => {
      Toast.success(I18n.t("ProjectSettingPage_configAdvanced_updateMsg"));
      getAppBackendConfig(project, appbackend_userId);
    });
    this.setState({ displayConfirm: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  handleChange(type, value = false) {
    if (value) {
      this.setState({ allowClient: true });
      this.props.change("allowClientClassCreation", true);
    }
    if (!value) {
      this.setState({ allowClient: false });
      this.props.change("allowClientClassCreation", false);
    }
  }
  handleModify(type, value = false) {
    if (value) {
      this.setState({ enableAnonymous: true });
      this.props.change("enableAnonymousUsers", true);
    }
    if (!value) {
      this.setState({ enableAnonymous: false });
      this.props.change("enableAnonymousUsers", false);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ displayConfirm: true });
  }
  showToolTip = (type) => {
    if (type === this.state.showTool) {
      this.setState({ showTool: "" });
    } else {
      this.setState({ showTool: type });
    }
  };
  render() {
    const { appbackend } = this.props.user;
    const { allowClient, enableAnonymous } = this.state;
    const {
      valid,
      pristine,
      submitting,
      user: { isPending, restartPending },
    } = this.props;

    return (
      <div>
        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={this.state.displayConfirm}
          title="ProjectSettingPage_configAdvanced_save_title"
          description="ProjectSettingPage_configAdvanced_saveNote"
          btnText="ProjectSettingPage_configAdvanced_save_btn"
          btnType="primary"
          isPending={isPending}
        />
        {(isPending || restartPending) && (
          <div className={classes["loader-container"]}>
            <div className={classes["loader"]}>
              <i className="fa fa-spinner" aria-hidden="true" />
            </div>
          </div>
        )}
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <div className={classes.new}>
            <DataBlock
              full
              title={I18n.t(
                "ProjectSettingPage_configAdvanced_DataBlock_title"
              )}
              titleClass="bg-grey-lightest"
            >
              {appbackend && (
                <div className="form-row toggle-row clearfix">
                  <h4
                    style={{ marginLeft: "28px" }}
                    className={`pull-left ${classes.textSTY}`}
                  >
                    {I18n.t(
                      "ProjectSettingPage_configAdvanced_DataBlock_title2"
                    )}
                  </h4>
                  &nbsp;&nbsp;
                  <Switch
                    // checked={appbackend.allowClientClassCreation}
                    checked={allowClient}
                    onChange={(checked) => {
                      this.handleChange("type", checked);
                    }}
                  />
                </div>
              )}
              {appbackend && (
                <div className="form-row toggle-row clearfix">
                  <h4
                    style={{ marginLeft: "28px" }}
                    className={`pull-left ${classes.textSTY}`}
                  >
                    {I18n.t(
                      "ProjectSettingPage_configAdvanced_DataBlock_title3"
                    )}
                  </h4>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Switch
                    // value={appbackend.enableAnonymousUsers}
                    checked={enableAnonymous}
                    onChange={(checked) => {
                      this.handleModify("type", checked);
                    }}
                  />
                </div>
              )}
              <div style={{ marginLeft: "27px" }}>
                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {" "}
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_1_title"
                      )}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("password")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "13px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "password" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_1_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="passwordPolicy"
                        canCopy
                        style={{ marginTop: "15px" }}
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_1_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_2_title"
                      )}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("auth")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "auth" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_2_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="auth"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_2_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_3_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("adapter")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "adapter" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_3_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="filesAdapter"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_3_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {" "}
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_4_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("logger")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "logger" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_4_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="loggerAdapter"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_4_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_5_title"
                      )}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("upsize")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "upsize" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_5_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="maxUploadSize"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="number"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_5_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_6_title"
                      )}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("loglevel")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "loglevel" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_6_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="logLevel"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_6_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_7_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("sesslength")}
                      style={{
                        cursor: "sesslength",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "sesslength" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_7_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="sessionLength"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="number"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_7_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {" "}
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_8_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("maxlimit")}
                      style={{
                        cursor: "maxlimit",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "maxlimit" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_8_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="maxLimit"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="number"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_8_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {" "}
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_9_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("passreset")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "passreset" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_9_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="revokeSessionOnPasswordReset"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_9_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {" "}
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_10_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("lock")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "lock" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_10_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="accountLockout"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_10_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {" "}
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_11_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("custom")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "custom" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_11_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="customPages"
                        canCopy
                        component={TextBox}
                        type="text"
                        validate={trimm}
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_11_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_12_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("middleware")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "middleware" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_12_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="middleware"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_12_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_13_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("master")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "master" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_13_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="masterKeyIps"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_13_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <strong style={{ color: "#615f72" }}>
                      {I18n.t(
                        "ProjectSettingPage_configAdvanced_DetailsList_14_title"
                      )}{" "}
                    </strong>
                    <i
                      onMouseDown={() => this.showToolTip("read")}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      className="fa fa-question-circle"
                      aria-hidden="true"
                    />
                    {this.state.showTool === "read" ? (
                      <div className={classes.toolTipStyle}>
                        <p>
                          {I18n.t(
                            "ProjectSettingPage_configAdvanced_DetailsList_14_description"
                          )}
                        </p>
                      </div>
                    ) : null}
                    <div className={classes.filedss}>
                      <Field
                        name="readOnlyMasterKey"
                        canCopy
                        validate={trimm}
                        component={TextBox}
                        type="text"
                        placeholder={I18n.t(
                          "ProjectSettingPage_configAdvanced_DetailsList_14_input_placeholder"
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <PrimaryButton
                  type="submit"
                  disabled={pristine || !valid || submitting}
                >
                  <Translate value="saveBtn" />
                </PrimaryButton>
              </div>
            </DataBlock>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm({
  form: "configadvanced",
})(
  connect(
    (store) => ({
      user: store.user,
      form: store.form,
    }),
    {
      advancedConfig,
      getAppBackendConfig,
      getCurrentSuit,
    }
  )(ConfigAdvanced)
);
