import { reducer as notifications } from "react-notification-system-redux";
import appReducers from "reducers";
import { combineReducers } from "redux";

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    // Add sync reducers here
    notifications,
    ...appReducers,
    ...asyncReducers,
  });
};

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
