import { Pagination } from "antd";
import { Confirm, NewBreadcrumb } from "components";
import DataTable from "components/DataTable";
import _ from "lodash";
import { getSuitBySubdomain } from "modules/user";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { I18n } from "react-redux-i18n";
import {
  checkAllTableRows,
  checkDataTableRow,
  clearActionMenu,
  deleteDeepLink,
  getDeepLinksCount,
  getSuitDeepLinksDataTable,
  onColumnToggle,
  onResetColumns,
  refershData,
  sortTable,
  toggleRowActionMenu,
} from "reducers/deeplink";
import { getAppBackendConfig } from "reducers/user";
import { connect } from "routes/routedComponent";
import { Toast } from "../../../../../modules/toast";
import classes from "./ListDeepLinks.scss";
import Menu from "./Menu";

class ListDeepLinksContainer extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    let currentSuit = getSuitBySubdomain(
      this.props.params.subdomain.toLowerCase()
    );

    this.state = {
      show: false,
      DeepLinkId: null,
      noData: false,
      shortLink: null,
      exelload: false,
      searchVal: null,
      clearKey: false,
      SelectUnistallDate: "daily",
      period: "day",
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
      dateChanged: false,
      formats: {
        year: "YYYY",
        month: "YYYY-MM",
        day: "YYYY-MM-DD",
        hour: "YYYY-MM-DD hh:mma",
      },
      startDateLock: false,
      sourceDate: null,
      sortedBy: "DATE CREATED_1",
      globalCheck: false,
      filtersOpen: false,
      filter: false,
      fromDate: moment(currentSuit.created_at).format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      limitVal: 20,
      skipVal: 0,
      currentPage: 1,
      totalCount: 0,
    };
  }

  componentWillMount() {
    this.listDeepLinks();
    this.deeplinkCount();
  }

  onChangePagination = async (page) => {
    this.setState(
      {
        currentPage: page,
        skipVal: (page - 1) * this.state.limitVal,
      },
      () => {
        this.listDeepLinks();
        // scroll to top
        window.scrollTo(0, 0);
      }
    );
  };

  onDateChanged = (fromDate, toDate) => {
    this.setState(
      { dateChanged: true, fromDate, toDate },
      this.handleAnalytics
    );
  };

  onFilterOptionSelect = (value) => {
    this.setState(
      { filter: { ...this.state.filter, value } },
      this.handleAnalytics
    );
  };

  onGlobalCheckboxClicked = () => {
    this.setState({ globalCheck: !this.state.globalCheck }, () => {
      this.props.checkAllTableRows(this.state.globalCheck);
    });
  };

  // filter
  onOptionSelect = (name, category, filterName) => {
    this.setState({
      filter: {
        name: name,
        category,
        filterName,
      },
    });
  };

  onSortClicked = (key, type) => {
    if (this.state.sortedBy === key) {
      this.setState({ sortedBy: key + "_1" });
      this.props.sortTable(key + "_1", type);
    } else {
      this.setState({ sortedBy: key });
      this.props.sortTable(key, type);
    }
  };

  clearFilter = () => {
    this.setState({ filter: null, filtersOpen: false }, this.handleAnalytics);
  };

  clearMenu = () => {
    this.setState({ filter: null });
  };

  deeplinkCount() {
    const {
      getDeepLinksCount,
      getAppBackendConfig,
      params,
      user: { activeSuitId, suits },
    } = this.props;
    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    getAppBackendConfig(params.subdomain, appbackend_userId).then(() => {
      const {
        user: { appbackend },
      } = this.props;
      if (params.action !== "new" && appbackend) {
        getDeepLinksCount(
          appbackend.serverURL + "fe",
          appbackend.appId,
          appbackend.masterKey
        );
      }
    });
  }

  delete = () => {
    const {
      deleteDeepLink,
      getSuitDeepLinksDataTable,
      user: { appbackend },
    } = this.props;
    const { DeepLinkId } = this.state;
    deleteDeepLink(
      appbackend.serverURL + "fe",
      DeepLinkId,
      appbackend.appId,
      appbackend.masterKey
    )
      .then(() => {
        Toast.success(
          I18n.t("ProjectSettingPage_listDeepLinks_deleteSuccessMsg")
        );
        getSuitDeepLinksDataTable(
          appbackend.serverURL + "fe",
          appbackend.appId,
          appbackend.masterKey
        ),
          this.deeplinkCount();
      })
      .catch((err) => {
        console.error("err delete appdeeplink", err);
      });
    this.handleDismiss();
  };

  handleActionClicked = (idx, actionName) => {
    const {
      deeplinks: { deeplinks },
      params,
    } = this.props;

    const deeplinkId = deeplinks.rows[idx].id;

    switch (actionName) {
      case "Edit":
        this.context.router.push(
          `/app/${params.subdomain}/appbackend/deeplinks/${deeplinkId}`
        );
        break;
      case "Delete":
        this.handleShow(deeplinkId);
    }
  };

  handleAnalytics = () => {
    const {
      getSuitDeepLinksDataTable,
      getAppBackendConfig,
      params,
      user: { activeSuitId, suits },
    } = this.props;

    const { limitVal, skipVal } = this.state;
    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    const { filter, period, fromDate, toDate } = this.state;

    getAppBackendConfig(params.subdomain, appbackend_userId).then(() => {
      const {
        user: { appbackend },
      } = this.props;

      if (!this.state.filter && !this.state.dateChanged) {
        getSuitDeepLinksDataTable(
          appbackend.serverURL + "fe",
          appbackend.appId,
          appbackend.masterKey,
          limitVal,
          skipVal
        );
        this.deeplinkCount();
      } else {
        getSuitDeepLinksDataTable(
          appbackend.serverURL + "fe",
          appbackend.appId,
          appbackend.masterKey,
          limitVal,
          skipVal,
          _.pickBy({
            period,
            start: fromDate,
            end: toDate,
            name: _.get(filter, "value"),
          })
        );
      }
    });
  };

  handleDismiss = () => {
    this.setState({ show: false });
  };

  handleShow = (DeepLinkId) => {
    this.setState({ show: true, DeepLinkId });
  };

  listDeepLinks() {
    const {
      getSuitDeepLinksDataTable,
      getAppBackendConfig,
      params,
      user: { activeSuitId, suits },
    } = this.props;
    let SuitArr = suits.filter((suit) => suit.id === activeSuitId);
    const appbackend_userId = SuitArr[0].appbackend_userId;
    getAppBackendConfig(params.subdomain, appbackend_userId).then(() => {
      const {
        user: { appbackend },
      } = this.props;
      const { limitVal, skipVal } = this.state;
      if (params.action !== "new" && appbackend) {
        getSuitDeepLinksDataTable(
          appbackend.serverURL + "fe",
          appbackend.appId,
          appbackend.masterKey,
          limitVal,
          skipVal
        ).then(() => {
          this.setState({
            noData: true,
          });
        });
      }
    });
  }

  uploadCSV = () => {
    this.context.router.push(
      `/app/${this.props.params.subdomain}/appbackend/deeplinks/upload`
    );
  };

  render() {
    const {
      deeplinks: { deeplinks, isPending, deeplinksCount },
      params: { subdomain },
      checkDataTableRow,
      onColumnToggle,
      onResetColumns,
      toggleRowActionMenu,
    } = this.props;

    const { show, currentPage, limitVal, globalCheck } = this.state;

    return (
      <section className={classes.listDeepLinks}>
        <Confirm
          onConfirm={this.delete}
          onClose={this.handleDismiss}
          display={show}
          title="ProjectSettingPage_listDeepLinks_delete_title"
          description="ProjectSettingPage_listDeepLinks_deleteNote"
          btnText="ProjectSettingPage_listDeepLinks_delete_title"
          btnType="critical"
          isPending={isPending}
        />

        <div className={classes.header}>
          <NewBreadcrumb fullPath={["Settings", "App DeepLinks"]} />
          <div className={classes.headerButtons}>
            <button
              className={classes.filterBtn}
              onClick={() => {
                if (
                  this.state.filter &&
                  this.state.filter.value &&
                  this.state.filter.name
                ) {
                  this.setState({ filtersOpen: false });
                } else {
                  this.setState({
                    filtersOpen: !this.state.filtersOpen,
                  });
                }
              }}
            >
              <i className="fa fa-filter" aria-hidden="true" />
              {I18n.t("filterBtn")}
            </button>
            <button
              className={classes.newDeepLinkBtn}
              onClick={() => {
                this.context.router.push(
                  `/app/${subdomain}/appbackend/deeplinks/new`
                );
              }}
            >
              <i className="fa fa-plus" />
              {I18n.t("ProjectSettingPage_listDeepLinks_createBtn")}
            </button>
          </div>
        </div>
        <DataTable
          checkDataTableRow={checkDataTableRow}
          onColumnToggle={onColumnToggle}
          onResetColumns={onResetColumns}
          onSortClicked={this.onSortClicked}
          onGlobalCheckboxClicked={this.onGlobalCheckboxClicked}
          toggleRowActionMenu={toggleRowActionMenu}
          largeActionMenu
          tableData={deeplinks}
          globalChecked={globalCheck}
          isPending={isPending}
          filterContainer
          renderFilterContainer={() => {
            return (
              <div>
                {this.state.filtersOpen &&
                  (!this.state.filter ||
                    (this.state.filter &&
                      this.state.filter.name &&
                      !this.state.filter.value)) && (
                    <div className={classes["dropdown-container"]}>
                      <DropdownButton
                        bsStyle={"default"}
                        title={I18n.t(
                          "ProjectSettingPage_listDeepLinks_addFilter"
                        )}
                        noCaret
                        key={1}
                        id={`dropdown-basic-${1}`}
                      >
                        {
                          <MenuItem
                            eventKey={deeplinks.labels[0].name}
                            onSelect={(eventKey) =>
                              this.onOptionSelect(
                                eventKey,
                                deeplinks.labels[0].name,
                                deeplinks.labels[0].name
                              )
                            }
                          >
                            {deeplinks.labels[0].name}
                          </MenuItem>
                        }
                      </DropdownButton>
                    </div>
                  )}
                {this.state.filter &&
                  this.state.filter.name &&
                  !this.state.filter.value && (
                    <Menu
                      data={this.props.deeplinks.deeplinks.rows}
                      index={this.props.deeplinks.deeplinks.labels
                        .map((label) => label.name)
                        .indexOf(this.state.filter.name)}
                      filter={this.state.filter}
                      onFilterOptionSelect={this.onFilterOptionSelect}
                      clearMenu={this.clearMenu}
                    />
                  )}
                {this.state.filter &&
                  this.state.filter.name &&
                  this.state.filter.value && (
                    <div className={classes["filter-block-selected"]}>
                      <div>
                        {this.state.filter.category} equals to{" "}
                        <strong>{this.state.filter.value}</strong>
                        <i
                          className={`${classes["filter-box-item-icon"]} fa fa-times`}
                          aria-hidden="true"
                          onClick={this.clearFilter}
                        />
                      </div>
                    </div>
                  )}
              </div>
            );
          }}
          order={[{ type: "gear" }, { type: "upload" }]}
          actions={[
            {
              name: "Delete",
            },
            { name: "Edit" },
          ]}
          handleActionClicked={this.handleActionClicked}
          clearActionMenu={this.props.clearActionMenu}
          csvFileName="DeepLinks.csv"
          uploadCSV={this.uploadCSV}
        />

        <div className={classes.paginationRow}>
          <Pagination
            size="small"
            defaultCurrent={1}
            current={currentPage}
            onChange={this.onChangePagination}
            pageSize={limitVal}
            total={deeplinksCount}
            hideOnSinglePage
          />
        </div>
      </section>
    );
  }
}

export default connect(
  (store) => ({
    deeplinks: store.deeplink,
    user: store.user,
  }),
  {
    getSuitDeepLinksDataTable,
    getDeepLinksCount,
    deleteDeepLink,
    getAppBackendConfig,
    refershData,
    toggleRowActionMenu,
    checkDataTableRow,
    onColumnToggle,
    onResetColumns,
    sortTable,
    checkAllTableRows,
    clearActionMenu,
  }
)(ListDeepLinksContainer);
