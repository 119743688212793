import { Input } from "antd";
import React from "react";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getAppBackendConfig, updateAppBackendConfig } from "reducers/user";
import { Label, PrimaryButton, SpinLoader } from "../../../../../components";
import {
  getNotifyConfig,
  setNotifyConfig,
} from "../../../../../reducers/config";

export class Android extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedConfigFile: null,
      data: null,
    };
  }

  async componentDidMount() {
    this.fetchAppPushConfig();
  }

  fetchAppPushConfig = async () => {
    const { getNotifyConfig } = this.props;

    try {
      const data = await getNotifyConfig(this.props.user.activeSuitId);

      this.setState({ data: data.value.body.channels.appPush.android });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      user: { activeSuitId },
      setNotifyConfig,
    } = this.props;

    const payload = {
      channels: {
        appPush: {
          android: {
            ...this.state.data,
          },
        },
      },
    };

    await setNotifyConfig(activeSuitId, payload);

    this.fetchAppPushConfig();
  };

  onChange = async (e) => {
    this.setState({ selectedConfigFile: e.target.files[0] });

    // parse json file
    const file = e.target.files[0];
    const reader = new FileReader();

    const readFile = new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const data = JSON.parse(event.target.result);
        resolve(data);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });

    reader.readAsText(file);

    try {
      const data = await readFile;
      console.log("data", data);
      this.setState({ data });
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };

  render() {
    const { configPending } = this.props;

    return (
      <div style={{ marginTop: "20px" }} className="">
        {configPending && <SpinLoader />}

        <form
          onSubmit={this.handleSubmit}
          style={{
            maxWidth: "500px",
          }}
        >
          <div>
            <Label>Current Configuration</Label>
            <Input.TextArea
              rows={10}
              value={
                JSON.stringify(
                  this.state.data || "No Configuration",
                  null,
                  2
                ) || "No Configuration"
              }
              disabled
            />
          </div>

          <div>
            <Label label={I18n.t("AndroidPush_configFile")} />
            <Input
              required
              type="file"
              accept="application/json"
              onChange={this.onChange}
              style={{
                marginBottom: "24px",
                height: "38px",
              }}
            />
          </div>

          <PrimaryButton type="submit" disabled={configPending}>
            {I18n.t("save")}
          </PrimaryButton>
        </form>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    form: store.form,
    configPending: store.config.isPending,
  }),
  {
    getAppBackendConfig,
    updateAppBackendConfig,
    showNotification: Notifications.show,
    setNotifyConfig,
    getNotifyConfig,
  }
)(Android);
