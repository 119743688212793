import {
  Confirm,
  DataBlock,
  DetailsList,
  Grid,
  PrimaryButton,
} from "components";
import React from "react";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
} from "reducers/user";
import classes from "./../Setting.scss";
class ApiAndSdk extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      displayDelete: false,
      displayCreate: false,
      displayConfirm: false,
    };
  }

  onModalConfirm = () => {
    this.generatAPI();
    this.setState({ displayConfirm: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };
  generatAPI() {
    const {
      generateAPIKey,
      user: { suit, activeSuitId },
      getCurrentSuit,
    } = this.props;
    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };
    generateAPIKey(data, suit.id).then(() => {
      this.props.showNotification(
        {
          title: "",
          message: I18n.t("ApiAndSdkPage_generatedMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );
      getCurrentSuit(activeSuitId);
    });
  }

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;

    fillDemoData(activeSuitId);

    this.handleCreateDismiss();
  };

  handleCreateDismiss = () => {
    this.setState({ displayCreate: false });
  };

  handleCreateShow = () => {
    this.setState({ displayCreate: true });
  };

  handleDeleteButton = () => {
    const {
      user: { activeSuitId },
      clearDemoData,
    } = this.props;

    clearDemoData(activeSuitId);

    this.handleDismiss();
  };

  handleDeleteShow = () => {
    this.setState({ displayDelete: true });
  };

  handleDismiss = () => {
    this.setState({ show: false, displayDelete: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  render() {
    const {
      user: { suit, isPending },
    } = this.props;

    return (
      <Grid fluid>
        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={this.state.displayConfirm}
          title="ApiAndSdkPage_generate"
          description="ApiAndSdkPage_generateNote"
          btnText="ApiAndSdkPage_generate"
          btnType="primary"
          isPending={isPending}
        />
        {isPending && (
          <div className={classes["loader-container"]}>
            <div className={classes["loader"]}>
              <i className="fa fa-spinner" aria-hidden="true" />
            </div>
          </div>
        )}
        {suit ? (
          <div className={classes.new}>
            <DataBlock title="apiAndSDK" titleClass="bg-grey-lightest">
              <DetailsList
                data={[
                  {
                    key: "ApiAndSdkPage_DetailsList_1_title",
                    value: suit.id || "NA",
                    description: I18n.t(
                      "ApiAndSdkPage_DetailsList_1_description"
                    ),
                    canCopy: true,
                    info: true,
                  },
                  {
                    key: "APIKey",
                    value: suit.api_key || "NA",
                    description: I18n.t(
                      "ApiAndSdkPage_DetailsList_2_description"
                    ),
                    canCopy: true,
                    info: true,
                  },
                ]}
              />
              <PrimaryButton
                value={I18n.t("ApiAndSdkPage_generate")}
                onClick={() => this.setState({ displayConfirm: true })}
              />
            </DataBlock>
          </div>
        ) : null}
      </Grid>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
  }),
  {
    generateAPIKey,
    getCurrentSuit,
    getMobileMarketingPlans,
    getMobileAppPlans,
    fillDemoData,
    clearDemoData,
    showNotification: Notifications.show,
  }
)(ApiAndSdk);
