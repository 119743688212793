import EP from "../consts/api";
import api from "../modules/api";

export default class DeepLinks {
  static getSuitDeepLinks = (serv, appid, master, limitVal, skipVal) => {
    return api(
      "get",
      EP.DEEPLINKS.getSuitDeepLinks(serv, limitVal, skipVal),
      false,
      false,
      false
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static getDeepLinksCount = (serv, appid, master) => {
    return api("get", EP.DEEPLINKS.getDeepLinksCount(serv), false, false, false)
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static createDeepLink = (data, serv, appid, master) => {
    return api("post", EP.DEEPLINKS.ALL(serv), data, false, false)
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");
  };

  static deleteDeepLink = (serv, DeepLinkId, appid, master) => {
    return api(
      "delete",
      EP.DEEPLINKS.SINGLE(serv, DeepLinkId),
      false,
      false,
      false
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static getCurrentDeepLink = (DeepLinkId, serv, appid, master) => {
    return api(
      "get",
      EP.DEEPLINKS.SINGLE(serv, DeepLinkId),
      false,
      false,
      false
    )
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static updateDeepLink = (DeepLinkId, data, serv, appid, master) => {
    return api("put", EP.DEEPLINKS.SINGLE(serv, DeepLinkId), data, false, false)
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };
  static uploadBulkDeepLink = (suitId, data) =>
    api("post", EP.DEEPLINKS.UPLOAD(suitId), data);
}
