import { Switch } from "antd";
import axios from "axios";
import {
  Confirm,
  DataBlock,
  DetailsList,
  FileUploadInput,
  MultiValueInput,
  PrimaryButton,
  SpinLoader,
} from "components";
import _ from "lodash";
import uploadToImgur from "modules/imgur";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  deleteSuit,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
  removeCurrentSuit,
  updateSuit,
} from "reducers/user";
import { Field, reduxForm } from "redux-form";
import { connect } from "routes/routedComponent";
import { config } from "../../../../consts/api";
import { getSuitBySubdomain } from "../../../../modules/user";
import classes from "./../Setting.scss";

class ProjectInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectIcon: "",
      fileSizeError: false,
      projectProfile: "",
      domainText: [],
      lock: false,
      domainFieldHasText: false,
    };

    this.state = {
      show: false,
      displayDelete: false,
      displayCreate: false,
      editClicked: false,
      initialized: false,
      imgLoading: {},
      displayConfirm: false,
      report: false,
      urlText: "",
      projectName: "",
      iconURL: "",
      weeklyReportToggle: true,
      weeklyReportToggleLoading: false,
    };
  }

  componentDidMount() {
    const {
      getSuitBySubdomain,
      user: { suit },
    } = this.props;

    const subDomain = window.location.pathname.split("/")[2].toLowerCase();
    let currentSuit = suit;

    if (!currentSuit) {
      currentSuit = getSuitBySubdomain(subDomain);
    }

    this.setState({
      domainText: currentSuit.domain ? currentSuit.domain : ["NA"],
      weeklyReportToggle: currentSuit.weekly_reports,
    });
  }

  onModalConfirm = () => {
    this.handleDeleteButton();
  };

  cancelClicked = () => {
    this.setState({ lock: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  editFunction = () => {
    const {
      user: { suit },
    } = this.props;

    this.setState({
      projectName: suit.name,
      urlText: suit.web_url,
      domainText: suit.domain ? suit.domain : [],
      iconURL: suit.app_icon_url,
    });
    if (this.state.lock) {
      this.setState({ lock: false });
    } else {
      this.setState({ lock: true });
    }
  };

  fileSizeCheck = (file) => {
    return file.size / 1000 <= 500;
  };

  generatAPI() {
    const {
      generateAPIKey,
      user: { suit },
    } = this.props;

    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };

    generateAPIKey(data, suit.id).then(() => {});
  }

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;

    fillDemoData(activeSuitId);
    this.handleCreateDismiss();
  };

  handleCreateDismiss = () => {
    this.setState({ displayCreate: false });
  };

  handleCreateShow = () => {
    this.setState({ displayCreate: true });
  };

  handleDeleteButton = () => {
    const {
      deleteSuit,
      user: { activeSuitId },
    } = this.props;

    deleteSuit(activeSuitId).then(() => {
      this.props.showNotification(
        {
          title: "",
          message: I18n.t("ProjectSettingPage_projectInfo_deleteMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );
      this.context.router.push("/");
    });
    this.handleDismiss();
  };

  handleDeleteShow = () => {
    this.setState({ displayDelete: true });
  };

  handleDismiss = () => {
    this.setState({ displayDelete: false, show: false });
  };

  handleSave = () => {
    const {
      updateSuit,
      user: { activeSuitId, suit },
      getCurrentSuit,
    } = this.props;

    let data = _.pickBy({
      status: "active",
      domain: this.state.domainText,
      suit_name: this.state.projectName,
      web_url: this.state.urlText === suit.web_url ? null : this.state.urlText,
      app_icon_url:
        this.state.iconURL === suit.app_icon_url ? null : this.state.iconURL,
      reset_fields: [],
    });

    updateSuit(activeSuitId, { ...data })
      .then(() => {
        this.props.showNotification(
          {
            title: "",
            message: I18n.t("ProjectSettingPage_projectInfo_editedMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        getCurrentSuit(activeSuitId);
      })
      .then(this.setState({ lock: false }));
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleToggleWeeklyReports = (value) => {
    // will use axios as no need to use redux and cause load on redux and no need for a full page loading
    this.setState({ weeklyReportToggleLoading: true });
    const {
      user: { activeSuitId, suit },
    } = this.props;

    const data = { weekly_reports: value, suit_name: suit.name };

    const { API_HOST } = config;
    const url = `${API_HOST}/apps/${activeSuitId}`;
    axios
      .put(url, data, {
        headers: {
          appApiKey: suit.api_key,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        this.setState({ weeklyReportToggle: value });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.setState({ weeklyReportToggleLoading: false });
      });
  };

  handleUploadImg = (e, value) => {
    if (!this.fileSizeCheck(value)) {
      this.setState({ fileSizeError: true });
      return;
    }
    this.setState({ fileSizeError: false });
    this.setState({ imgLoading: true });
    uploadToImgur(
      "app_icon_url",
      this.props,
      value,
      (data) => {
        if (data.data.link) {
          this.setState({ imgLoading: false, iconURL: data.data.link });
        }
      },
      () => this.setState({ imgLoading: false })
    );
  };

  render() {
    const {
      user: { suit, isPending },
    } = this.props;

    const { weeklyReportToggleLoading, weeklyReportToggle } = this.state;

    let customDomain = suit?.plan_summary?.plan_product.some(
      (product) => product.name === "Custom Domain"
    );

    let changedFields = {};

    if (suit) {
      changedFields = _.pickBy({
        status: "active",
        domain: this.state.domainText,
        suit_name: this.state.projectName,
        web_url:
          this.state.urlText === suit.web_url ? null : this.state.urlText,
        app_icon_url:
          this.state.iconURL === suit.app_icon_url ? null : this.state.iconURL,
        reset_fields: [],
      });
    }

    return (
      <div>
        {isPending && <SpinLoader />}

        {suit ? (
          <div className={classes.new}>
            <DataBlock
              title="ProjectSettingPage_projectInfo_DataBlock_title"
              titleClass="bg-grey-lightest"
            >
              <DetailsList
                functionParent={this.takeInputData}
                data={
                  customDomain
                    ? [
                        {
                          key: "ProjectName",
                          value: suit.name || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_1_description"
                          ),
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <input
                              value={this.state.projectName}
                              onChange={(e) =>
                                this.setState({ projectName: e.target.value })
                              }
                              style={{
                                width: "220px",
                                border: "1px solid grey",
                                borderRadius: "4px",
                                padding: "5px",
                              }}
                              placeholder={I18n.t(
                                "ProjectSettingPage_projectInfo_DetailsList_1_input_placeholder"
                              )}
                              type="text"
                            />
                          ),
                        },
                        {
                          key: "Subdomain",
                          value: `${suit.subdomain}.appgain.io` || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_2_description"
                          ),
                          canCopy: true,
                          info: true,
                        },
                        {
                          key: "ProjectSettingPage_projectInfo_DetailsList_3_title",
                          value: suit.type || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_3_description"
                          ),
                          canCopy: false,
                          info: true,
                        },
                        {
                          key: "ProjectSettingPage_projectInfo_DetailsList_4_title",
                          value: suit.app_icon_url || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_4_description"
                          ),
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <div
                              style={{ width: "218px", marginBottom: "51px" }}
                            >
                              <Field
                                name="logo"
                                style={{ marginBottom: "20px" }}
                                accept="image/*"
                                component={FileUploadInput}
                                iconClass={
                                  this.state.imgLoading[
                                    "components[0].logo.src"
                                  ]
                                    ? "fa fa-spinner fa-spin"
                                    : "fa fa-camera"
                                }
                                onChange={this.handleUploadImg}
                                hint={I18n.t(
                                  "512px*512px, Size < or = to 500kb"
                                )}
                                optional
                                disabled={
                                  this.state.imgLoading[
                                    "components[0].logo.src"
                                  ]
                                }
                              />
                            </div>
                          ),
                        },

                        {
                          key: "ProjectWebsite",
                          value: suit.web_url || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_5_description"
                          ),
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <input
                              value={this.state.urlText}
                              onChange={(e) =>
                                this.setState({ urlText: e.target.value })
                              }
                              style={{
                                width: "220px",
                                border: "1px solid grey",
                                borderRadius: "4px",
                                padding: "5px",
                              }}
                              placeholder="http://myweb.com/"
                              type="text"
                            />
                          ),
                        },
                        {
                          key: "CustomDomain",
                          value:
                            this.state.domainText &&
                            Array.isArray(this.state.domainText)
                              ? this.state.domainText.join(" | ")
                              : "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_6_description"
                          ),
                          link: "ProjectSettingPage_projectInfo_DetailsList_6_link",
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <div>
                              <MultiValueInput
                                name="domain"
                                values={this.state.domainText || []}
                                placeholder="Enter your Domain"
                                onValuesChange={(newValues) => {
                                  this.setState({ domainText: newValues });
                                }}
                              />
                              <p>
                                for Multi Domains, Press Enter after each Domain
                              </p>
                            </div>
                          ),
                        },
                      ]
                    : [
                        {
                          key: "ProjectName",
                          value: suit.name || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_1_description"
                          ),
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <input
                              value={this.state.projectName}
                              onChange={(e) =>
                                this.setState({ projectName: e.target.value })
                              }
                              style={{
                                width: "220px",
                                border: "1px solid grey",
                                borderRadius: "4px",
                                padding: "5px",
                              }}
                              placeholder={I18n.t(
                                "ProjectSettingPage_projectInfo_DetailsList_1_input_placeholder"
                              )}
                              type="text"
                            />
                          ),
                        },
                        {
                          key: "Subdomain",
                          value: `${suit.subdomain}.appgain.io` || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_2_description2"
                          ),
                          canCopy: true,
                          info: true,
                        },
                        {
                          key: "ProjectSettingPage_projectInfo_DetailsList_3_title",
                          value: suit.type || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_3_description"
                          ),
                          canCopy: false,
                        },
                        {
                          key: "ProjectSettingPage_projectInfo_DetailsList_4_title",
                          value: suit.app_icon_url || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_4_description"
                          ),
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <div
                              style={{ width: "218px", marginBottom: "51px" }}
                            >
                              <Field
                                name="logo"
                                style={{ marginBottom: "20px" }}
                                accept="image/*"
                                component={FileUploadInput}
                                iconClass={
                                  this.state.imgLoading[
                                    "components[0].logo.src"
                                  ]
                                    ? "fa fa-spinner fa-spin"
                                    : "fa fa-camera"
                                }
                                onChange={this.handleUploadImg}
                                hint={I18n.t(
                                  "512px*512px, Size < or = to 500kb"
                                )}
                                optional
                                disabled={
                                  this.state.imgLoading[
                                    "components[0].logo.src"
                                  ]
                                }
                              />
                              <div></div>
                            </div>
                          ),
                        },
                        {
                          key: "ProjectWebsite",
                          value: suit.web_url || "NA",
                          description: I18n.t(
                            "ProjectSettingPage_projectInfo_DetailsList_5_description"
                          ),
                          canCopy: false,
                          info: true,
                          lock: this.state.lock,
                          inputField: (
                            <input
                              value={this.state.urlText}
                              onChange={(e) =>
                                this.setState({ urlText: e.target.value })
                              }
                              style={{
                                width: "220px",
                                border: "1px solid grey",
                                borderRadius: "4px",
                                padding: "5px",
                              }}
                              placeholder="http://myweb.com/"
                              type="text"
                            />
                          ),
                        },
                      ]
                }
              />
              <div className={classes.toggle}>
                <p>{I18n.t("getWeeklyReport")}</p>

                <Switch
                  loading={weeklyReportToggleLoading}
                  checked={weeklyReportToggle}
                  onChange={(checked) =>
                    this.handleToggleWeeklyReports(checked)
                  }
                />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "18px" }}
              >
                {this.state.fileSizeError && (
                  <p className={classes.imgSizeError}>
                    {I18n.t("filesizeMsg")}
                  </p>
                )}
                {this.state.lock === true ? (
                  <PrimaryButton
                    disabled={
                      this.state.domainFieldHasText ||
                      (Object.keys(changedFields).length === 3 &&
                        changedFields.suit_name === suit.name)
                    }
                    onClick={() => {
                      this.handleSave();
                    }}
                  >
                    {I18n.t("saveBtn")}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    disabled={this.state.domainFieldHasText}
                    onClick={() => {
                      this.editFunction();
                    }}
                    value={I18n.t("edit")}
                  />
                )}
                <PrimaryButton
                  onClick={() => this.setState({ displayConfirm: true })}
                  danger
                >
                  {I18n.t("deleteBtn")}
                </PrimaryButton>
              </div>
            </DataBlock>
          </div>
        ) : null}

        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={this.state.displayConfirm}
          title="ProjectSettingPage_projectInfo_deteleBtn"
          description="ProjectSettingPage_projectInfo_deleteNote"
          btnText="ProjectSettingPage_projectInfo_deteleBtn"
          btnType="critical"
          isPending={isPending}
        />
      </div>
    );
  }
}
export default reduxForm({
  form: "configadvanced",
})(
  connect(
    (store) => ({
      user: store.user,
      select: store.select,
    }),
    {
      generateAPIKey,
      getCurrentSuit,
      getMobileMarketingPlans,
      getMobileAppPlans,
      fillDemoData,
      deleteSuit,
      clearDemoData,
      updateSuit,
      removeCurrentSuit,
      getSuitBySubdomain,
      showNotification: Notifications.show,
    }
  )(ProjectInfo)
);
