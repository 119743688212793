import classnames from "classnames";
import { Col, Grid, Row } from "components";
import React from "react";
import { Translate } from "react-redux-i18n";
import classes from "./DataBlock.scss";

const DataBlock = ({
  className,
  title,
  children,
  full,
  titleClass,
  rowClass = "",
  style,
}) => {
  return (
    <Grid fluid style={style} className={className || ""}>
      <h3
        className={`${classnames(classes.title, {
          [classes.full]: full,
        })} oldtitle`}
      >
        <span className={titleClass}>
          <Translate value={title} />
        </span>
      </h3>
      <Row className={rowClass} style={{ marginLeft: "20px" }}>
        <Col md={12}>{children}</Col>
      </Row>
    </Grid>
  );
};

export default DataBlock;
