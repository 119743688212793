import { Tooltip } from "antd";
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Translate } from "react-redux-i18n";
import Copyicon from "../Copyicon";
import classes from "./DetailsList.scss";

class DetailsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: "hidden",
      outvisible: true,
      projectIcon: "",
      projectProfile: "",
      CustomDomain: "",
    };
  }
  handleInput = (e, type) => {
    this.props.functionParent(e, type);
  };
  visibleTool = (condition) => {
    if (condition) {
      this.setState({ visible: "visible", outvisible: false });
    } else {
      this.setState({ visible: "hidden", outvisible: true });
    }
  };
  render() {
    const { data } = this.props;

    return (
      <dl>
        <div>
          {data.map(
            ({
              key,
              value,
              description,
              href,
              link,
              canCopy,
              info,
              inputField,
              lock,
              displayConnectBtn,
              connectBtn,
            }) => (
              <div key={key}>
                <dt
                  style={{
                    fontSize: "16px",
                    color: "#030d18",
                    fontWeight: "100",
                  }}
                >
                  <Translate value={key} />
                  {info && !href && (
                    <Tooltip title={description}>
                      <i
                        data-tip={description}
                        style={{
                          cursor: "pointer",
                          color: "#24a0ed",
                          fontSize: "16px",
                          marginLeft: "6px",
                        }}
                        className="fa fa-question-circle"
                        aria-hidden="true"
                      />
                    </Tooltip>
                  )}
                  {info && href && link ? (
                    <i
                      onMouseDown={() => this.visibleTool(true)}
                      className={`${classes.tooltip} fa fa-question-circle`}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      aria-hidden="true"
                    >
                      <OutsideClickHandler
                        disabled={this.state.outvisible}
                        onOutsideClick={() => {
                          this.visibleTool(false);
                        }}
                      >
                        <span id="tooltiptext" className={classes.tooltiptext}>
                          <Translate value={description} />
                          <a href={href}>
                            <Translate value={link} />
                          </a>
                        </span>
                      </OutsideClickHandler>
                    </i>
                  ) : null}
                </dt>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#7c8c9b",
                    fontWeight: "50",
                    marginBottom: "16px",
                  }}
                  title={value}
                >
                  {inputField && lock ? (
                    inputField
                  ) : (
                    <span className={classes.widthsize}>{value}</span>
                  )}
                  {canCopy && <Copyicon value={value} />}
                </div>
                <div>{displayConnectBtn && connectBtn}</div>
              </div>
            )
          )}
        </div>
      </dl>
    );
  }
}

export default DetailsList;
