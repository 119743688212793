import { Col, FormControl, Row } from "components";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import { connect } from "routes/routedComponent";
import classes from "./SMSConfig.scss";
import AWS_SMS from "./components/AWS_SMS";
import Alfa from "./components/Alfa";
import CEQUENS from "./components/CEQUENS";
import Msegat from "./components/Msegat";
import SMS_Country from "./components/SMS_Country";
import SMS_Global from "./components/SMS_Global";
import SMS_Jormail from "./components/SMS_Jormail";
import SmsMisr from "./components/SmsMisr";
import Twilio from "./components/Twilio";
import UNIFONIC from "./components/UNIFONIC";
import VictoryLink from "./components/VictoryLink";

const SMSOptions = [
  { value: "AWS-SMS", label: I18n.t("PlatformsPage_nav_3_smsOption_1") },
  { value: "SMSGlobal", label: I18n.t("PlatformsPage_nav_3_smsOption_2") },
  { value: "Unifonic", label: I18n.t("PlatformsPage_nav_3_smsOption_3") },
  { value: "VictoryLink", label: I18n.t("PlatformsPage_nav_3_smsOption_4") },
  { value: "Twilio", label: I18n.t("PlatformsPage_nav_3_smsOption_5") },
  { value: "Alfa", label: I18n.t("PlatformsPage_nav_3_smsOption_6") },
  { value: "CEQUENS", label: I18n.t("PlatformsPage_nav_3_smsOption_7") },
  { value: "SMS-JORMAIL", label: I18n.t("PlatformsPage_nav_3_smsOption_8") },
  { value: "SMS_Country", label: I18n.t("PlatformsPage_nav_3_smsOption_9") },
  { value: "SMS-MISR", label: I18n.t("PlatformsPage_nav_3_smsOption_10") },
  { value: "SMS-MSEGAT", label: "Msegat" },
];

class SMSConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      requires_auth: false,
      requires_authVendors: false,
      disabled: true,
      TypeSMS: "AWS-SMS",
      validateSelect: false,
    };
  }
  async componentWillMount() {
    const {
      config: { notify },
      user: { activeSuitId },
      getNotifyConfig,
    } = this.props;

    if (notify && notify.channels && notify.channels.sms) return;

    await getNotifyConfig(activeSuitId);
  }

  handleEdit = () => {
    this.setState({
      disabled: false,
    });
  };

  handleModify = (type, value = false) => {
    if (value) {
      this.setState({ requires_auth: true });

      this.props.change("requires_authVendors", true);
    }
    if (!value) {
      this.setState({ requires_auth: false });
      this.props.change("requires_authVendors", false);
    }
  };

  handleSave = () => {
    this.setState({
      disabled: true,
    });
  };

  handleSelectSMS = (e) => {
    this.setState({ TypeSMS: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      setNotifyConfig,
      getNotifyConfig,
      form,
      user: { activeSuitId },
    } = this.props;
    const { TypeSMS } = this.state;
    const values = form.nconfig.values;
    let connectionInfo;

    switch (TypeSMS) {
      case "VictoryLink":
        connectionInfo = {
          connection_info: {
            type: "SMS-VICTORYLINK",
            UserName: values.UserName,
            Password: values.Password,
            SMSLang: values.SMSLang,
            SMSSender: values.SMSSender,
          },
        };
        break;
      case "Twilio":
        connectionInfo = {
          connection_info: {
            type: "SMS-TWILIO",
            account_sid: values.account_sid,
            sender: values.sender,
            auth_token: values.auth_token,
          },
        };
        break;

      case "SMS-MISR":
        connectionInfo = {
          connection_info: {
            type: "SMS-MISR",
            username: values.username,
            password: values.password,
            sender: values.sender,
          },
        };
        break;

      case "Alfa":
        connectionInfo = {
          connection_info: {
            type: "SMS-ALFA",
            apiKey: values.apiKey,
            sender: values.sender,
          },
        };
        break;

      case "CEQUENS":
        connectionInfo = {
          connection_info: {
            type: "SMS-CEQUENS",
            accesstoken: values.accesstoken,
            senderName: values.senderName,
          },
        };
        break;

      case "UNIFONIC":
        connectionInfo = {
          connection_info: {
            type: "SMS-UREST",
            AppSid: values.AppSid,
          },
        };
        break;

      case "AWS-SMS":
        connectionInfo = {
          connection_info: {
            type: "AWS-SNS",
            access_key_ID: values.sms_access_key_ID,
            aws_region: values.sms_aws_region,
            secret_access_key: values.sms_secret_access_key,
          },
        };
        break;

      case "SMSGlobal":
        connectionInfo = {
          connection_info: {
            type: "HTTP_GET",
            url: values.url,
          },
        };
        break;

      case "Unifonic":
        connectionInfo = {
          connection_info: {
            type: "UREST",
            AppSid: values.AppSid,
          },
        };
        break;

      case "SMS_Country":
        connectionInfo = {
          connection_info: {
            type: "SMS-COUNRTY",
            User: values.User,
            passwd: values.passwd,
          },
        };
        break;

      case "SMS-JORMAIL":
        connectionInfo = {
          connection_info: {
            type: "SMS-JORMAIL",
            UserName: values.UserName,
            Password: values.Password,
            SenderID: values.SenderID,
          },
        };
        break;

      case "SMS-MSEGAT":
        connectionInfo = {
          connection_info: {
            type: "SMS-MSEGAT",
            UserName: values.UserName,
            SenderID: values.SenderID,
            apiKey: values.apiKey,
          },
        };
        break;

      default:
        break;
    }

    let conInfoClone = { ...connectionInfo.connection_info };

    for (let key in conInfoClone) {
      if (
        typeof conInfoClone[key] === "string" &&
        conInfoClone[key].includes("**")
      ) {
        delete conInfoClone[key];
      }
    }

    connectionInfo.connection_info = conInfoClone;

    let notifyConfigData = {
      channels: {
        sms: {
          ...connectionInfo,
          vendor: values.vendor,
        },
      },
    };

    setNotifyConfig(activeSuitId, notifyConfigData)
      .then(() => {
        this.props.showNotification(
          {
            title: "",
            message: I18n.t("PlatformsPage_nav_3_smsOption_updateMsg"),
            position: "bc",
            autoDismiss: 5,
          },
          "success"
        );
        getNotifyConfig(activeSuitId);
      })
      .then(() => {
        this.handleSave();
      });
  };

  render() {
    const { TypeSMS, validateSelect } = this.state;
    const {
      config: { notify, isPending },
    } = this.props;
    if (notify && notify.channels && notify.channels.sms && !validateSelect) {
      let smstype = null;
      smstype = notify.channels.sms.connection_info.type;
      if (smstype === "HTTP_GET") {
        this.setState({ TypeSMS: "SMSGlobal" });
      } else if (smstype === "AWS-SNS") {
        this.setState({ TypeSMS: "AWS-SMS" });
      } else if (smstype === "UREST") {
        this.setState({ TypeSMS: "Unifonic" });
      } else if (smstype === "SMS-CEQUENS") {
        this.setState({ TypeSMS: "CEQUENS" });
      } else if (smstype === "SMS-VICTORYLINK") {
        this.setState({ TypeSMS: "VictoryLink" });
      } else if (smstype === "SMS-TWILIO") {
        this.setState({ TypeSMS: "Twilio" });
      } else if (smstype === "SMS-ALFA") {
        this.setState({ TypeSMS: "Alfa" });
      } else if (smstype === "SMS-JORMAIL") {
        this.setState({ TypeSMS: "SMS-JORMAIL" });
      } else if (smstype === "SMS-MISR") {
        this.setState({ TypeSMS: "SMS-MISR" });
      } else if (smstype === "SMS-MSEGAT") {
        this.setState({ TypeSMS: "SMS-MSEGAT" });
      } else {
        this.setState({ TypeSMS: "SMS_Country" });
      }
      this.setState({ validateSelect: true });
    }

    return (
      <div style={{ marginTop: "20px" }}>
        {isPending && (
          <div className={classes["loader-container"]}>
            <div className={classes["loader"]}>
              <i className="fa fa-spinner" aria-hidden="true" />
            </div>
          </div>
        )}
        <Row>
          <Col md={6}>
            <label> {I18n.t("PlatformsPage_ServiceVendor")}</label>
            <br />
            <div style={{ marginTop: "20px" }} className={classes.PlatForms}>
              <FormControl
                componentClass="select"
                value={TypeSMS}
                onChange={this.handleSelectSMS}
              >
                {SMSOptions.map((el) => (
                  <option key={el.value} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </FormControl>
            </div>
            <br />
            <br />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {TypeSMS === "VictoryLink" && (
              <VictoryLink
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "SMS-MISR" && (
              <SmsMisr
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "Alfa" && (
              <Alfa
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "Twilio" && (
              <Twilio
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "CEQUENS" && (
              <CEQUENS
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "AWS-SMS" && (
              <AWS_SMS
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}

            {TypeSMS === "SMSGlobal" && (
              <SMS_Global
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "Unifonic" && (
              <UNIFONIC
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
            {TypeSMS === "SMS_Country" && (
              <SMS_Country
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}

            {TypeSMS === "SMS-JORMAIL" && (
              <SMS_Jormail
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}

            {TypeSMS === "SMS-MSEGAT" && (
              <Msegat
                handleModify={this.handleModify}
                handleSubmit={this.handleSubmit}
                handleEdit={this.handleEdit}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    config: store.config,
    form: store.form,
  }),
  {
    setNotifyConfig,
    getNotifyConfig,
    showNotification: Notifications.show,
  }
)(SMSConfig);
