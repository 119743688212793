// -------------------------------------------------------
// Development configurations
// -------------------------------------------------------

const PRODUCTION = "production";
const DEVELOPMENT = "development";
const DEVOPS = "devops";

// mode enum
const MODE_ENUM = {
  PRODUCTION,
  DEVELOPMENT,
  DEVOPS,
};

const MODE = MODE_ENUM.DEVELOPMENT;

const cloudFun_dev =
  "https://preprodappbackend.appgain.io/610aa03b8d52dc0051713d3d/stgcoreapp/functions";
const cloudFun_prod =
  "https://appbackend.appgain.io/5c694933ade3f3000ffc9d16/core/functions";

const development = {
  DOMAIN: "appgain.io",
  SENTRY_URL: "https://10ca161d84c3429da33945991ba9ed82@sentry.io/1218470",
  STRIPE_APIKEY: "pk_test_7u5WMLl1tWkLdvDY8CtnGaxI",
  StripSecretKey: "Bearer sk_test_b4kYamXYdRDm5a7rdUUNXlhP",
  STRIPE_INVOICES_KEY: "Bearer rk_test_3jXUgYceSAdlHhMU0R9VOBp8",
  ledformURL: `${cloudFun_dev}/leadPostGETByName?`,
  StripPayURL: `${cloudFun_dev}/stripeGETByName?`,
  LastOrderURL: `${cloudFun_dev}/lastOrderSinceNdays?`,
  userScreenURL: `${cloudFun_dev}/getUsers?`,
  addProfileURL: `${cloudFun_dev}/addUser?`,
  getInvoicesURL: `${cloudFun_dev}/invoiceGET?`,
  kpiURL: `${cloudFun_dev}/smartRetargetingStoreKPI?`,
  reportsURL: `${cloudFun_dev}/smartRetargetingReports?`,
  appBoostSummary: `${cloudFun_dev}/appBoostSummarySection?`,
  appBoostRevenueAndTransactions: `${cloudFun_dev}/appBoostRevenueAndTransactions?`,
  appBoostActiveUsers: `${cloudFun_dev}/appBoostActiveUsers?`,
  appBoostUninstalls: `${cloudFun_dev}/appBoostUninstalls?`,
  appBoostImportCustomers: `${cloudFun_dev}/appBoostImportCustomers?`,
  serverUrlUploadCsv: `https://preproapi.appgain.io/file_uploader`,
  parseUrlAppBoost: `https://preprodappbackend.appgain.io/610aa03b8d52dc0051713d3d/stgcoreapp/functions/appBoostImportCustomers`,
  parseUrlSmartRetargeting: `https://preprodappbackend.appgain.io/610aa03b8d52dc0051713d3d/stgcoreapp/functions/smartRetargetingImportCustomers`,
  appPushSchedulerUrl: `https://preprodsechulder.instabackend.io/notifyjob`,

  Masterkey:
    "MASTER-b9370874260604838c8fff3065b1b0e2e6606f78af7f83ab7dc915f3a08839a8",
  applicationID: "stgcoreapp",
  userDroppedCreateNewProjectProjectId: "610bd14e8d52dc00117463b4",
  userDroppedCreateNewProjectApiKey:
    "8d9f1a5dc8316a575594dccfdfd21eda8eec0486b2b8fe8efa05aaac85a9c2ae",
  servUrlInvocations: `https://preprodnotify.appgain.io`,
  parseServerURLUploadCSV: `${cloudFun_dev}/smartRetargetingImportCustomers?`,

  mobileMarketingParseUrl:
    "https://preprodappbackend.appgain.io/610aa03b8d52dc0051713d3d/stgcoreapp",
};

const extraDevelopmentKeys = {
  API_HOST: `https://preprodapi.${development.DOMAIN}`,
  AUTH: `https://preprodapi.${development.DOMAIN}/google`,
  PARSE_SERVER: `https://preprodappbackend.${development.DOMAIN}`,
  PARSE_CLOUD_URL: `${development.API_HOST}/parsecloud`,
  SMARTLINK_HOST: development.API_HOST,
  NOTIFY_HOST: `https://preprodnotify.${development.DOMAIN}`,
  SEND_LATER_HOST: `https://preprodsechulder.instabackend.io`,
  APPBACKENDSERVER_HOST: `https://preprodappbackendserver.${development.DOMAIN}`,
  AUTOMATOR_HOST: `https://preprodautomator.${development.DOMAIN}`,
  APPBACKEND_HOST: `https://preprodappbackend.${development.DOMAIN}/`,
  projectId: `${development.userDroppedCreateNewProjectProjectId}`,
  parseServURLUploadCSV: `${development.parseServerURLUploadCSV}`,
  adminServer: "https://preprodadminapi.appgain.io",
};

extraDevelopmentKeys.MOBILE_LANDING = `${extraDevelopmentKeys.API_HOST}/ml`;
extraDevelopmentKeys.MOBILE_LANDING_PREVIEW = `${extraDevelopmentKeys.API_HOST}/ml/preview`;

Object.assign(development, extraDevelopmentKeys);

const production = {
  DOMAIN: "appgain.io",
  SENTRY_URL: "https://b39e7530815a44aa8587a2945b8ae32c@sentry.io/145362",
  STRIPE_APIKEY: "pk_live_bnqV577Db4TVJVprUs2H34lF",
  StripSecretKey: "Bearer rk_live_32YYo1CATATYsQpLLPpYRQlG",
  STRIPE_INVOICES_KEY: "Bearer rk_live_kydgZDFV0V9JIZGo8s7Vky4e",
  ledformURL: `${cloudFun_prod}/leadPostGETByName?`,
  StripPayURL: `${cloudFun_prod}/stripeGETByName?`,
  LastOrderURL: `${cloudFun_prod}/lastOrderSinceNdays?`,
  userScreenURL: `${cloudFun_prod}/getUsers?`,
  addProfileURL: `${cloudFun_prod}/addUser?`,
  getInvoicesURL: `${cloudFun_prod}/invoiceGET?`,
  kpiURL: `${cloudFun_prod}/smartRetargetingStoreKPI?`,
  reportsURL: `${cloudFun_prod}/smartRetargetingReports?`,
  appBoostSummary: `${cloudFun_prod}/appBoostSummarySection?`,
  appBoostRevenueAndTransactions: `${cloudFun_prod}/appBoostRevenueAndTransactions?`,
  appBoostActiveUsers: `${cloudFun_prod}/appBoostActiveUsers?`,
  appBoostUninstalls: `${cloudFun_prod}/appBoostUninstalls?`,
  appBoostImportCustomers: `${cloudFun_dev}/appBoostImportCustomers?`,
  serverUrlUploadCsv: `https://feapi.appgain.io/file_uploader`,
  parseUrlAppBoost: `https://appbackend.appgain.io/60d35026bb8e23001231d296/testers/functions/appBoostImportCustomers`,
  parseUrlSmartRetargeting: `https://appbackend.appgain.io/60d35026bb8e23001231d296/testers/functions/smartRetargetingImportCustomers`,
  appPushSchedulerUrl: `https://sechulder.instabackend.io/notifyjob`,
  Masterkey:
    "MASTER-f072941b51872f6795814f5c6cbea60ab57f40a41a494a3a80d53161f2c483f7",
  applicationID: "core",
  userDroppedCreateNewProjectProjectId: "5918826f27af4d6722e820e0",
  userDroppedCreateNewProjectApiKey:
    "397bef636fd4e44c212916493fab762268e74527bef732183c9b031011d3a5b4",
  servUrlInvocations: `https://notify.appgain.io`,
  parseServerURLUploadCSV: `${cloudFun_prod}/smartRetargetingImportCustomers`,

  mobileMarketingParseUrl:
    "https://appbackend.appgain.io/5c694933ade3f3000ffc9d16/core",
};

const extraProductionKeys = {
  API_HOST: `https://feapi.${production.DOMAIN}`,
  AUTH: `https://auth.${production.DOMAIN}/google`,
  PARSE_SERVER: `https://appbackend.${production.DOMAIN}`,
  PARSE_CLOUD_URL: `${production.API_HOST}/parsecloud`,
  SMARTLINK_HOST: production.API_HOST,
  NOTIFY_HOST: `https://notify.${production.DOMAIN}`,
  SEND_LATER_HOST: `https://sechulder.${production.DOMAIN}`,
  APPBACKENDSERVER_HOST: `https://appbackendserver.${production.DOMAIN}`,
  AUTOMATOR_HOST: `https://automator.${production.DOMAIN}`,
  APPBACKEND_HOST: `https://appbackend.${production.DOMAIN}/`,
  projectId: `${production.userDroppedCreateNewProjectProjectId}`,
  parseServURLUploadCSV: `${production.parseServerURLUploadCSV}`,
  adminServer: "https://adminapi.appgain.io",
};

extraProductionKeys.MOBILE_LANDING = `${extraProductionKeys.API_HOST}/ml`;
extraProductionKeys.MOBILE_LANDING_PREVIEW = `${extraProductionKeys.API_HOST}/ml/preview`;

Object.assign(production, extraProductionKeys);

const devops = {
  ...production,
  serverUrlUploadCsv: `https://devopspi.appgain.io/file_uploader`,
  servUrlInvocations: `https://devopsnotify.appgain.io`,
};

const extraDevopsKeys = {
  API_HOST: `https://devopsapi.${devops.DOMAIN}`,
  AUTH: `https://auth.${devops.DOMAIN}/google`,
  PARSE_SERVER: `https://appbackend.${devops.DOMAIN}`,
  PARSE_CLOUD_URL: `${devops.API_HOST}/parsecloud`,
  SMARTLINK_HOST: devops.API_HOST,
  NOTIFY_HOST: `https://devopsnotify.${devops.DOMAIN}`,
  SEND_LATER_HOST: `https://sechulder.${devops.DOMAIN}`,
  APPBACKENDSERVER_HOST: `https://appbackendserver.${devops.DOMAIN}`,
  AUTOMATOR_HOST: `https://devopsautomator.${devops.DOMAIN}`,
  APPBACKEND_HOST: `https://appbackend.${devops.DOMAIN}/`,
  projectId: `${devops.userDroppedCreateNewProjectProjectId}`,
  parseServURLUploadCSV: `${devops.parseServerURLUploadCSV}`,
  adminServer: "https://devopsadminapi.appgain.io",
};

Object.assign(devops, extraDevopsKeys);

const initConfig = {
  MODE,
  MODE_ENUM,
  ...{
    [MODE_ENUM.PRODUCTION]: production,
    [MODE_ENUM.DEVELOPMENT]: development,
    [MODE_ENUM.DEVOPS]: devops,
  }[MODE],

  REQUEST_DEMO: "https://www.appgain.io/request-demo",
  AUTH_TOKEN: true,
  RETAIL_GAIN_APP_URL: "https://central.appgain.io/s/SJKBx",
};

initConfig.LeadFormdynamic = `${initConfig.ledformURL}`;
initConfig.StripPaydynamic = `${initConfig.StripPayURL}`;
initConfig.userScreendynamic = `${initConfig.userScreenURL}`;
initConfig.LastOrderdynamic = `${initConfig.LastOrderURL}`;
initConfig.addProfileURL = `${initConfig.addProfileURL}`;
initConfig.getInvoicesdynamic = `${initConfig.getInvoicesURL}`;
initConfig.kpidynamic = `${initConfig.kpiURL}`;
initConfig.appBoostSum = `${initConfig.appBoostSummary}`;
initConfig.appBoostRnT = `${initConfig.appBoostRevenueAndTransactions}`;
initConfig.appBoostActive = `${initConfig.appBoostActiveUsers}`;
initConfig.appBoostUnins = `${initConfig.appBoostUninstalls}`;
initConfig.appBoostImportCus = `${initConfig.appBoostImportCustomers}`;
initConfig.servUrlUploadCsv = `${initConfig.serverUrlUploadCsv}`;
initConfig.parseURLAppBoost = `${initConfig.parseUrlAppBoost}`;
initConfig.parseURLSmartRetargeting = `${initConfig.parseUrlSmartRetargeting}`;
initConfig.servURLInvocations = `${initConfig.servUrlInvocations}`;
initConfig.CLIENT_ID = "94e28f154d385e1";
initConfig.AcessCode = "1024appgain";

export const config = Object.freeze({ ...initConfig });

// --------------------------------------------------------
// API end points
// --------------------------------------------------------

const {
  API_HOST,

  APPBACKEND_HOST,
  APPBACKENDSERVER_HOST,
  AUTOMATOR_HOST,
  SEND_LATER_HOST,
  NOTIFY_HOST,
  LeadFormdynamic,
  StripPaydynamic,

  LastOrderdynamic,
  addProfileURL,

  kpidynamic,

  reportsURL,
  projectId,
  servUrlUploadCsv,

  servURLInvocations,
  parseServURLUploadCSV,
  adminServer,
  mobileMarketingParseUrl,
} = config;
export default {
  USER: {
    LOGIN: () => `${API_HOST}/login`,
    DELETEUSER: () => `${API_HOST}/user`,
    // GOOGLELOGIN: () => `${API_HOST}/google`,
    // REGISTER: () => `${API_HOST}/register`,
    // Changed to request demo link
    REGISTER: () => `${API_HOST}/register`,

    FORGOT_PASSWORD: () => `${API_HOST}/passrecovery`,

    RESETPASSWORD: (token) => `${API_HOST}/reset_password?token=${token}`,

    RESEND_CONFIRMATION: (email) => `${API_HOST}/resend-link/${email}`,

    APPS: () => `${API_HOST}/apps`,
    USER_LINK: () => `${API_HOST}/user`,

    DeleteDemoData: (suitId) => `${API_HOST}/delete_dummy_data/${suitId}`,

    APP: (appId) => `${API_HOST}/apps/${appId}`,

    GENERATE: (AppId) => `${API_HOST}/apps/${AppId}`,

    addON: (suitId) => `${API_HOST}/${suitId}/buyAddon`,

    FillDemoData: (suitId) => `${API_HOST}/create_dummy_data/${suitId}`,

    ClearDemoData: (suitId) => `${API_HOST}/delete_dummy_data/${suitId}`,

    checkSubscribed: (serverURL, suitId, apiKey) =>
      `${serverURL}/functions/checkSubscribed?projectId=${suitId}&apiKey=${apiKey}`,
    getAppCurrency: (serverURL, suitId, apiKey) =>
      `${serverURL}/functions/getAppCurrency?projectId=${suitId}&apiKey=${apiKey}`,
    upgradePlan: (suit_id, plan_id) => `${API_HOST}/apps/${suit_id}/${plan_id}`,
    SubUser: () => `${API_HOST}/custom_user`,
    PAYMENT_CONFIG: (suitId) => `${API_HOST}/apps/${suitId}/payments`,
  },
  MediaLibrary: {
    GetMediaLibrary: (suitId) => `${API_HOST}/${suitId}/list_media_files`,

    DeleteMedia: (suitId) => `${API_HOST}/${suitId}/delete_media_files`,
  },
  STRIPE: {
    TOKEN: (userid) => `${API_HOST}/stripeToken/${userid}`,
    StripeInfo: (stripeCustomerId) =>
      `https://api.stripe.com/v1/customers/${stripeCustomerId}/sources`,
    INVOICES: () => "https://api.stripe.com/v1/invoices",
  },

  USER_LINK: () => "/user",

  APP: (id) => `/apps/${id}`,

  // ------------------------------
  // smartlinksgetSuitAppPushs
  // ------------------------------
  SMARTLINKS: {
    ALL: (appId) => `${API_HOST}/apps/${appId}/smartlinks`,
    getSuitSmartlinks: (suitId, apiKey, data, page) => {
      let url = `${API_HOST}/apps/${suitId}/smartlinks`;
      if (data) {
        const filter = Object.keys(data).map((key) => `${key}=${data[key]}`);
        url += `?${filter.join("&")}`;
      }
      if (page) {
        url += `&page=${page}`;
      }
      return url;
    },
    SINGLE: (appId, smartlinkId) =>
      `${API_HOST}/apps/${appId}/smartlinks/${smartlinkId}`,

    BULK: (appId) => `${API_HOST}/apps/${appId}/smartlinks/bulk`,

    ANALYTICS: (appId, smartlinkId) =>
      `${API_HOST}/smartlinks/${appId}/stats/summary/${smartlinkId}`,

    UPLOAD: (suitId, email) =>
      `${API_HOST}/apps/${suitId}/smartlinksbulk?email=${email}`,
    STASTICS: (appId) =>
      `${API_HOST}/apps/${appId}/smartlinks?statssummary=true`,

    CHART_DATA: (appId, smartlinkId, filter) =>
      `${API_HOST}/apps/${appId}/smartlinks/${smartlinkId}/stats/chartdata${filter}`,

    HITSLOGS: (appId, smartlinkId) =>
      `${API_HOST}/apps/${appId}/smartlinks/${smartlinkId}/stats/hitslogs`,

    // search
    SEARCHSMARTLINKS: (appId, nameVal) =>
      `${API_HOST}/apps/${appId}/smartlinks?name=${nameVal}`,
  },
  // ------------------------------
  // landingpages => Micro Pages
  // ------------------------------
  LANDINGPAGES: {
    ALL: (appId) => `${API_HOST}/apps/${appId}/landingpages`,
    getSuitLandingPages: (suitId, apiKey, data, page) => {
      let url = `${API_HOST}/apps/${suitId}/landingpages`;
      if (data) {
        const filter = Object.keys(data).map((key) => `${key}=${data[key]}`);
        url += `?${filter.join("&")}`;
      }
      if (page) {
        url += `&page=${page}`;
      }
      return url;
    },

    SINGLE: (appId, landingpageId) =>
      `${API_HOST}/apps/${appId}/landingpages/${landingpageId}`,

    BULK: (appId) => `${API_HOST}/apps/${appId}/landingpages/bulk`,

    STASTICS: (appId) =>
      `${API_HOST}/apps/${appId}/landingpages?statssummary=true`,

    SEARCH: (appId, nameVal) =>
      `${API_HOST}/apps/${appId}/landingpages?name=${nameVal}`,

    ANALYTICS: (appId, landingpageId) =>
      `${API_HOST}/landingpages/${appId}/stats/summary/${landingpageId}`,
  },
  // ------------------------------
  // leadForm
  // ------------------------------
  LEADFORM: {
    ALLForm: (appId, apiKey, landingpageId) =>
      `${LeadFormdynamic}projectID=${appId}&apiKey=${apiKey}&formName=${landingpageId}`,
  },
  // ------------------------------
  // ------------------------------
  // StripPay
  // ------------------------------
  STRIPPAY: {
    ALLStrip: (appId, apiKey, landingpageId) =>
      `${StripPaydynamic}projectID=${appId}&apiKey=${apiKey}&formName=${landingpageId}`,
  },
  // ------------------------------
  // TemplatesPage
  // ------------------------------

  TEMPLATES: {
    ALL: (appId) => `${NOTIFY_HOST}/${appId}/templates`,

    SINGLE: (appId, templateId) =>
      `${NOTIFY_HOST}/${appId}/templates/${templateId}`,

    BULK: (appId) => `${NOTIFY_HOST}/${appId}/templates/bulk`,
  },
  // ------------------------------
  // PUSH
  // ------------------------------
  PUSHSTATICTICS: (appId) => `${NOTIFY_HOST}/${appId}/summary`,

  LISTPUSH: (appId) => `${NOTIFY_HOST}/${appId}/campaigns`,

  getSuitAppPushs: (suitId, data) =>
    `${NOTIFY_HOST}/${suitId}/campaigns?type=appPush&period=day&start=${
      data.start
    }&end=${data.end}${data.page ? `&page=${data.page}` : ""}`,

  getSuitWebPushs: (suitId, data) =>
    `${NOTIFY_HOST}/${suitId}/campaigns?type=webPush&period=day&start=${
      data.start
    }&end=${data.end}${data.page ? `&page=${data.page}` : ""}`,

  getSuitSMS: (suitId, data) =>
    `${NOTIFY_HOST}/${suitId}/campaigns?type=SMS&period=day&start=${
      data.start
    }&end=${data.end}${data.page ? `&page=${data.page}` : ""}`,

  getSuitEmail: (suitId, apiKey, data) => {
    if (data) {
      return `${NOTIFY_HOST}/${suitId}/campaigns?type=email&period=day&start=${
        data.start
      }&end=${data.end}${data.page ? `&page=${data.page}` : ""}`;
    } else {
      return `${NOTIFY_HOST}/${suitId}/campaigns?type=email`;
    }
  },

  PUSH_DELETE_BULK: (appId) => `${NOTIFY_HOST}/${appId}/campaigns/bulk`,

  DELETEPUSH: (appId, campaignId) =>
    `${NOTIFY_HOST}/${appId}/campaigns/${campaignId}`,
  WEBPUSHSUBSCRIBERS: (appId) =>
    `${NOTIFY_HOST}/notification/${appId}/stats/webpush`,
  Campaign_ANALYTICS: (appId, campaignId) =>
    `${NOTIFY_HOST}/campaigns/${appId}/stats/summary/${campaignId}`,

  TARGET_LIST: {
    BULK: (appId) => `${NOTIFY_HOST}/${appId}/SMStargetlist/bulk`,

    ALL: (appId) => `${NOTIFY_HOST}/${appId}/SMStargetlist`,

    SINGLE: (appId, targetListName) =>
      `${NOTIFY_HOST}/${appId}/SMStargetlist/${targetListName}`,

    DOWNLOAD: (appId, targetListName) =>
      `${NOTIFY_HOST}/${appId}/SMStargetlist/${targetListName}/download`,
  },

  // search
  SEARCHPUSH: (appId, nameVal) =>
    `${NOTIFY_HOST}/${appId}/campaigns?name=${nameVal}`,

  // ------------------------------
  // Plans
  // ------------------------------
  PLANS: {
    ALL: () => `${API_HOST}/plans`,
  },
  // ------------------------------
  // parse
  // ------------------------------
  PARSE: {
    GET_USERS_COUNT: (appId) =>
      `${APPBACKEND_HOST}/${appId}/classes/_User?count=1&limit=0`,

    CLOUD_FUNCTION_URL: (userId, subdomain, appbackend_userId) =>
      `${APPBACKENDSERVER_HOST}/cloud/${appbackend_userId}/${subdomain}`,
  },
  // ------------------------------
  // appbackend
  // ------------------------------
  APPBACKEND: {
    GET_CONFIG: (userId, subdomain, appbackend_userId) =>
      `${APPBACKENDSERVER_HOST}/${appbackend_userId}/${subdomain}`,
    GET_SCHEMA: (subdomain, appbackend_userId) =>
      `${APPBACKEND_HOST}/${appbackend_userId}/${subdomain}/schemas/_User`,
    GET_APP_EVENT: (subdomain, appbackend_userId) =>
      `${APPBACKEND_HOST}/${appbackend_userId}/${subdomain}/classes/appEvents`,
    Bundle_Ios_Id: (suitId, userId, appbackend_userId) =>
      `${APPBACKENDSERVER_HOST}/${appbackend_userId}/${suitId}`,
    UPLOAD_IOS_FILES: (userId, suitId, subdomain, appbackend_userId) => {
      return `${APPBACKENDSERVER_HOST}/${appbackend_userId}/${suitId}/${subdomain}?upload_files=true`;
    },
    UPDATE_APPBACKEND_CONFIG: (userId, suitId, appbackend_userId) =>
      `${APPBACKENDSERVER_HOST}/${appbackend_userId}/${suitId}`,
    Restart_APP_Config: (userId, subdomain, appbackend_userId) =>
      `${APPBACKENDSERVER_HOST}/restart_app/${appbackend_userId}/${subdomain}`,
    Advanced_Config: (userId, appId, appbackend_userId) =>
      `${APPBACKENDSERVER_HOST}/${appbackend_userId}/${appId}`,
  },

  // ------------------------------
  // automator
  // ------------------------------
  AUTOMATOR: {
    AUTOMESSAGES: (suitId, page) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}${page ? "?page=" + page : ""}`,
    AUTOMESSAGESTRUE: (suitId) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}?automation=true`,

    AUTOMESSAGE: (suitId, automessageId) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}/${automessageId}`,

    ANALYTICS: (suitId, automessageId) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}/analytics/${automessageId}`,

    BULK_DELETE: (suitId) => `${AUTOMATOR_HOST}/automessages/${suitId}/bulk`,

    // user dorpped create new project

    USERDROPPEDCREATENEWPROJECT: (userId) =>
      `${AUTOMATOR_HOST}/automessages/${projectId}/firevent/userdroppedatnewproject/${userId}?email_subject="Create your project Now and get 1 Month Free"`,
    // Invocations
    INVOCATIONS: (suitId, apiKey, data) =>
      `${servURLInvocations}/${suitId}/campaigns?getAutomessage=true&start=${data.start}&end=${data.end}`,
  },
  // ------------------------------
  // deeplinks
  // ------------------------------
  DEEPLINKS: {
    ALL: (Serverkey, limitVal = 0, skipVal = 0) =>
      `${Serverkey}/classes/DeepLinks?limit=${limitVal}&skip=${skipVal}`,
    getSuitDeepLinks: (serv, limitVal, skipVal) => {
      let url = `${serv}/classes/DeepLinks?limit=${limitVal}&skip=${skipVal}`;

      return url;
    },
    getDeepLinksCount: (Serverkey) =>
      `${Serverkey}/classes/DeepLinks?limit=0&count=1`,
    SINGLE: (Serverkey, DeepLinkId) =>
      `${Serverkey}/classes/DeepLinks/${DeepLinkId}`,
    UPLOAD: (suitId) => `${API_HOST}/bulk_upload_deeplinks/${suitId}`,
    // SINGLE: (Serverkey, DeepLinkId) =>
    //    `${Serverkey}/classes/DeepLinks/${DeepLinkId}`,
  },
  // Customer Segments
  // ------------------------------
  SEGMENTS: {
    ALL: (Serverkey) =>
      `${Serverkey}/classes/AppSegments?skip=0&limit=99999&order=-updatedAt`,
    SINGLE: (Serverkey, SegmentId) =>
      `${Serverkey}/classes/AppSegments/${SegmentId}`,
    CLOUD: (serverURL) => `${serverURL}/functions/appboostAddUsersToSegment`,
    FORCE_UPDATE: (suitId) => `${adminServer}/update_appsegments/${suitId}`,
  },
  FUNNELS: {
    ALL: (Serverkey) => `${Serverkey}/classes/appFunnels?skip=0&limit=99999`,
    CREATE: (Serverkey) => `${Serverkey}/classes/appFunnels`,
    DELETE: (suitId, funnelId) =>
      `${API_HOST}/event_funnel/${suitId}/${funnelId}`,
    BULK: (suitId) => `${API_HOST}/${suitId}/funnels`,
    SINGLE: (suitId, funnelId, date) =>
      `${API_HOST}/event_funnel/${suitId}/${funnelId}${date}`,
    UPDATE_FUNNELS: (suitId) => `${API_HOST}/event_funnel/${suitId}`,
  },
  // ------------------------------
  // notify
  // ------------------------------
  NOTIFY: {
    CONFIG: (appId) => `${NOTIFY_HOST}/${appId}/config`,

    LOGS: (appId, type) => `${NOTIFY_HOST}/${appId}/logs/${type}`,
  },

  // Notify Logs
  NOTIFY_LOGS: {
    ALL_LOGS: (appId, page, start, end, type) =>
      type
        ? `${NOTIFY_HOST}/${appId}/logs/all?page=${page}&start=${start}&end=${end}&type=${type}`
        : `${NOTIFY_HOST}/${appId}/logs/all?page=${page}&start=${start}&end=${end}`,
  },

  // ------------------------------
  // send later
  // ------------------------------
  SEND_LATER: {
    NOTIFY: () => `${SEND_LATER_HOST}/notifyjob`,
    DELETE_USER: () => `${SEND_LATER_HOST}/create/job`,
  },

  USERSCREEN: {
    APPUSERS: (subDomain, skip, limit, count) => {
      return `${mobileMarketingParseUrl}/classes/Users${subDomain}${
        count
          ? "?count=1&limit=0"
          : `?skip=${skip}&limit=${limit}&order=-updatedAt`
      }`;
    },

    getAppUsersByFilter: () => `${mobileMarketingParseUrl}/functions/CRMFilter`,

    mobileUserInfo: (subDomain, userId) =>
      `${mobileMarketingParseUrl}/classes/Users${subDomain}/?where={"userId":${JSON.stringify(
        userId
      )}}&order=-updatedAt`,

    ADDUSERPROFILE: (userId, subdomain, email, name, phone, activeSuitId) =>
      `${addProfileURL}userId=${userId}&project_sub_domain=${subdomain}&subscriptionChannel=POS&email=${email}&name=${name}&mobileNum=${phone}&projectId=${activeSuitId}&session=1&ltv=0&lastOrderval=0&orders=0`,

    UserTimelineInfo: (appId, userId, subDomain) =>
      `${API_HOST}/user/${appId}/${userId}?project_sub_domain=${subDomain}`,

    UserLogEvents: (appId, userId, page) =>
      `${API_HOST}/user_log_events/${appId}/${userId}?page=${page}`,
  },

  // customer analytics upload csv file
  UPLOADCUSTMOERSCSV: {
    upload: (suitId, email, apiKey, subdomain) =>
      `${servUrlUploadCsv}/${suitId}?projectId=${suitId}&apiKey=${apiKey}&project_sub_domain=${subdomain}&email=${email}&parse_url=${parseServURLUploadCSV}`,
  },

  // App User
  APPUSER: {
    AllAppUsers: (serv, limitVal, skipVal, order) =>
      `${serv}/classes/_User?order=${order}&limit=${limitVal}&skip=${skipVal}`,
    NumberOfUsers: (serv) => `${serv}/classes/_User?count=1&limit=0`,

    UploadCsv: (suitId) => `${API_HOST}/csv/${suitId}/import_users`,

    // used when navigating from segments page to user rich profile page to reverse filtering and get users in the segment with the condition ready
    AllAppUsersWithFilterConditionUrl: (serv, condition, skipVal, limitVal) =>
      `${serv}/functions/getUsersByEvents?limit=${limitVal}&skip=${skipVal}&${condition}`,
    AllAppUsersWithFilterConditionUrlLength: (serv, condition) =>
      `${serv}/functions/getUsersByEvents?count=1&${condition}`,

    AllAppUsersWithFilter: (
      serv,
      limitVal,
      skipVal,
      filterDataArr,
      userId,
      apiKey,
      suitId
    ) =>
      `${serv}/functions/getUsersByEvents?match={"$and":${filterDataArr}}&accountId=${userId}&projectId=${suitId}&apiKey=${apiKey}&skip=${skipVal}&limit=${limitVal}&sort={ "updatedAt": -1 }`,
    AllAppUsersWithFilterLength: (
      serv,
      filterDataArr,
      userId,
      apiKey,
      suitId
    ) =>
      `${serv}/functions/getUsersByEvents?match={"$and":${filterDataArr}}&accountId=${userId}&projectId=${suitId}&apiKey=${apiKey}&group={"objectId":null,"sum":{"$sum":1}}`,
    AllAppUsersWithFilterUserIds: (
      serv,
      filterDataArr,
      userId,
      apiKey,
      suitId
    ) =>
      `${serv}/functions/getUsersByEvents?match={"$and":${filterDataArr}}&accountId=${userId}&projectId=${suitId}&apiKey=${apiKey}&project={ "userId": 1, "_id": 0}`,
    AllAppUsersWithFilterByEvents: (
      serv,
      suitId,
      apiKey,
      skipVal,
      limitVal,
      userId,
      subdomain
    ) => {
      return `${serv}/functions/filterUsers?projectId=${suitId}&apiKey=${apiKey}&accountId=${userId}&subdomain=${subdomain}&skip=${skipVal}&limit=${limitVal}`;
    },
    AllAppUsersWithFilterByEventsLength: (
      serv,
      suitId,
      apiKey,
      skipVal,
      limitVal,
      userId,
      subdomain
    ) => {
      return `${serv}/functions/filterUsers?projectId=${suitId}&apiKey=${apiKey}&accountId=${userId}&subdomain=${subdomain}&count=true&skip=${skipVal}&limit=${limitVal}`;
    },
    AllAppUsersWithFilterByEventsUserId: (
      serv,
      suitId,
      apiKey,
      skipVal,
      limitVal,
      userId,
      subdomain
    ) => {
      return `${serv}/functions/filterUsers?projectId=${suitId}&apiKey=${apiKey}&accountId=${userId}&subdomain=${subdomain}&ids=true`;
    },
    ExportCsvWithFilter: (suitId) => `${API_HOST}/upload_csv_to_s3/${suitId}`,
    getUsersData: (serverUrl) => `${serverUrl}/functions/getUsersData`,
  },

  DIGITALINOVICE: {
    INVOICES: (subdomain, data) => {
      const params = Object.keys(data).map((key) => `${key}=${data[key]}`);
      return `${mobileMarketingParseUrl}/functions/getInvoices?subdomain=${subdomain}&${params.join(
        "&"
      )}`;
    },

    createInvoice: () => `${mobileMarketingParseUrl}/functions/CreateInvoice`,

    mobileUserInvoices: (subDomain, userId) =>
      `${mobileMarketingParseUrl}/classes/Invoices${subDomain}/?where={"userId":${JSON.stringify(
        userId
      )}}&order=-updatedAt`,

    deleteInvoice: () => `${mobileMarketingParseUrl}/functions/deleteInvoice`,

    shareViaSMS: () =>
      `${mobileMarketingParseUrl}/functions/shareInvoiceViaSMS`,
    shareViaWhatsApp: () =>
      `${mobileMarketingParseUrl}/functions/shareInvoiceViaWhatsApp`,
  },
  LASTORDER: {
    Days: () =>
      `${LastOrderdynamic}userId=10003&project_sub_domain=marketer&subscriptionChannel=none&email=eng.m.shaheen@gmail.com&name=mohamed Shaheen&phone=00971559475149&session=1`,
  },

  KPI: {
    Kpi: (suitId, apiKey, subdomain, data) =>
      `${kpidynamic}&projectId=${suitId}&apiKey=${apiKey}&project_sub_domain=${subdomain}&fromDate=${data.start}&toDate=${data.end}`,
  },
  APPBOOST: {
    summ: (Serverkey, suitId, apiKey, data) =>
      `${Serverkey}/functions/appBoostSummarySection?projectId=${suitId}&apiKey=${apiKey}&fromDate=${data.start}&toDate=${data.end}`,
    Dailyrevenue: (Serverkey, suitId, apiKey, data) =>
      `${Serverkey}/functions/appBoostDailyMonthlyRevenue?projectId=${suitId}&apiKey=${apiKey}&fromDate=${data.start}&toDate=${data.end}`,
    RnT: (Serverkey, suitId, apiKey, data) =>
      `${Serverkey}/functions/appBoostRevenueAndTransactions?projectId=${suitId}&apiKey=${apiKey}&fromDate=${data.start}&toDate=${data.end}`,
    PurchasedTransaction: (Serverkey) =>
      `${Serverkey}/classes/PurchaseTransactions?order=-transactionAt`,
    PurchasedTransactionCount: (Serverkey) =>
      `${Serverkey}/classes/PurchaseTransactions`,
    ActiveUsers: (Serverkey, suitId, apiKey, data) =>
      `${Serverkey}/functions/appBoostActiveUsers?projectId=${suitId}&apiKey=${apiKey}&fromDate=${data.start}&toDate=${data.end}`,
    uninstall: (Serverkey, suitId, apiKey, data) =>
      `${Serverkey}/functions/appBoostUninstalls?projectId=${suitId}&apiKey=${apiKey}&fromDate=${data.start}&toDate=${data.end}&monthsLimit=12&daysLimit=10`,
    TouchPoint: (suitId, apiKey, data) =>
      `${API_HOST}/appBoost/${suitId}?Total_revenue=True&start=${data.start}&end=${data.end}`,
    Acqu: (suitId, apiKey, data) =>
      `${API_HOST}/appBoost/${suitId}?media_source=True&start=${data.start}&end=${data.end}`,
    getRevenueMetrics: (serverURL, suitId, apiKey, fromDate, toDate) =>
      `${serverURL}/functions/appBoostRevenueMetrics?projectId=${suitId}&apiKey=${apiKey}&fromDate=${fromDate}&toDate=${toDate}`,
  },

  REPORTS: {
    Reports: (suitId, apiKey, subdomain, start, end) =>
      `${reportsURL}&projectId=${suitId}&apiKey=${apiKey}&project_sub_domain=${subdomain}&fromDate=${start}&toDate=${end}`,
  },

  MOBILE_ATTRIBUTES: {
    getAttributes: (suitId) => `${API_HOST}/mobile_attributes/${suitId}`,
    listSmartLinks: (suitId) => `${API_HOST}/apps/${suitId}/smartlinks`,
    getCardsData: (suitId, start, end, filter, value) => {
      return !filter
        ? `${API_HOST}/mobile_attributes_counts/${suitId}?start=${start}&end=${end}`
        : `${API_HOST}/mobile_attributes_counts/${suitId}?start=${start}&end=${end}&${filter}=${value}`;
    },
    getChartData: (suitId, start, end, filter, value) => {
      return !filter
        ? `${API_HOST}/mobile_attributes_charts/${suitId}?start=${start}&end=${end}`
        : `${API_HOST}/mobile_attributes_charts/${suitId}?start=${start}&end=${end}&${filter}=${value}`;
    },
    getTableData: (suitId, start, end, filter, value) => {
      return !filter
        ? `${API_HOST}/mobile_attributes_all_stats/${suitId}?start=${start}&end=${end}`
        : `${API_HOST}/mobile_attributes_all_stats/${suitId}?start=${start}&end=${end}&${filter}=${value}`;
    },
  },
  UNINSTALL_MONITOR: {
    getData: (serverURL, suitId, apiKey, start, end) =>
      `${serverURL}/functions/uninstallMonitorAnalytics?projectId=${suitId}&apiKey=${apiKey}&fromDate=${start}&toDate=${end}&monthsLimit=12`,
    followUpUsers: (serverURL, suitId, apiKey, start, end) =>
      `${serverURL}/functions/uninstallMonitorFollowUp?projectId=${suitId}&apiKey=${apiKey}&fromDate=${start}&toDate=${end}`,
    cleanUpUsers: (serverURL, suitId, apiKey, email) =>
      `${serverURL}/functions/uninstallMonitorCleanUp?projectId=${suitId}&apiKey=${apiKey}&email=${email}`,
  },
  APP_PUSH: {
    SEND_APP_PUSH: (serverUrl) => `${serverUrl}`,
    SEND_APP_PUSH_SCHEDULER: () => `${SEND_LATER_HOST}/notifyjob`,
  },
  EVENTS: {
    CREATE_EVENT: (serverURL) => `${serverURL}/classes/appEvents`,
    LIST_EVENTS: ({ suitId, start, end, page }) =>
      `${API_HOST}/event_count/${suitId}?start=${start}&end=${end}&page=${page}`,
    GET_EVENT: ({ suitId, start, eventId, end, page }) =>
      `${API_HOST}/get_event/${suitId}/${eventId}/?start=${start}&end=${end}&page=${page}`,
    DELETE_EVENT_BY_NAME: ({ suitId, eventId }) =>
      `${API_HOST}/delete_event_by_name/${suitId}/${eventId}`,
    DELETE_EVENT_BULK_BY_NAME: ({ suitId }) =>
      `${API_HOST}/delete_event_bulk/${suitId}`,
    CLEAR_ALL_EVENTS: ({ suitId }) => `${API_HOST}/delete_all_events/${suitId}`,
  },
  bulkUploadCsv: {
    // type === webPush || appPush
    uploadBulkPush: (suitId, type) =>
      `${API_HOST}/upload_csv_to_scheduler/${suitId}?type=${type}`,
  },
  EVENTS_DEFINITION: {
    GET_EVENTS: (serverURL) => {
      return `${serverURL}/classes/appEvents?order=events`;
    },
    UPDATE_EVENT: ({ serverURL, objectId }) =>
      `${serverURL}/classes/appEvents/${objectId}`,
    DELETE_EVENT: ({ serverURL, objectId }) =>
      `${serverURL}/classes/appEvents/${objectId}`,
    ADD_EVENT: ({ serverURL }) => `${serverURL}/classes/appEvents`,
  },
  FACEBOOK: {
    getBusinessAccount: (accessToken) =>
      `${NOTIFY_HOST}/business_id?access_token=${accessToken}`,

    getAdAccount: (accessToken, businessId) =>
      `${NOTIFY_HOST}/get_business?access_token=${accessToken}&business_id=${businessId}`,

    getCustomAudiences: (accessToken, adAccountId) =>
      `${NOTIFY_HOST}/get_id?access_token=${accessToken}&ad_account_id=${adAccountId}&payload_url_type=HASHES_OR_USER_IDS`,

    createCustomAudiences: (accessToken, adAccountId, description, name) =>
      `${NOTIFY_HOST}/create?access_token=${accessToken}&name=${name}&description=${description}&ad_account_id=${adAccountId}`,
    generateLongAccessToken: (accessToken) =>
      `${NOTIFY_HOST}/long_token?access_token=${accessToken}`,
    syncSegment: (accessToken, suitId, customAudienceId, segmentId) =>
      `${NOTIFY_HOST}/segment_syn/${suitId}?access_token=${accessToken}&id_=${customAudienceId}&segment_id=${segmentId}`,
  },
  WHATSAPP: {
    getWhatsAppTemplates: (suitId) =>
      `${NOTIFY_HOST}/${suitId}/whatsapp/templates`,

    getWhatsAppPhoneNumbers: (suitId, wabaId) =>
      `${NOTIFY_HOST}/${suitId}/whatsapp/numbers/${wabaId}`,

    getWhatsAppBussinessAccountIds: (suitId, businessId) =>
      `${NOTIFY_HOST}/${suitId}/whatsapp/waba/${businessId}`,
  },
  PRODUCTS: {
    getProducts: (subdomain, skip, limit, count) =>
      `${mobileMarketingParseUrl}/classes/Products${subdomain}${
        count
          ? "?count=1&limit=0"
          : `?skip=${skip}&limit=${limit}&order=-createdAt`
      }`,

    deleteProduct: (subdomain, productId) =>
      `${mobileMarketingParseUrl}/classes/Products${subdomain}/${productId}`,

    deleteProductsBulk: () =>
      `${mobileMarketingParseUrl}/functions/deleteProductsBulk`,

    createProduct: (subdomain) =>
      `${mobileMarketingParseUrl}/classes/Products${subdomain}`,

    addProducts: () => `${mobileMarketingParseUrl}/functions/createProduct`,

    editProduct: (subdomain, objectId) =>
      `${mobileMarketingParseUrl}/classes/Products${subdomain}/${objectId}`,

    searchProducts: (search, limit, skip) =>
      `${mobileMarketingParseUrl}/functions/searchProducts?search=${search}&limit=${limit}&skip=${skip}`,

    exportProducts: () =>
      `${mobileMarketingParseUrl}/functions/exportAllProductsToCSV`,
  },

  COUPONS: {
    getCoupons: (subdomain, skip, limit) =>
      `${mobileMarketingParseUrl}/functions/getCoupons?skip=${skip}&limit=${limit}&subdomain=${subdomain}`,
    createCoupon: (subdomain) =>
      `${mobileMarketingParseUrl}/functions/createCoupon?subdomain=${subdomain}`,
    shareCoupon: () => `${mobileMarketingParseUrl}/functions/shareCoupon`,
    deleteCoupon: (subdomain, code) =>
      `${mobileMarketingParseUrl}/functions/deleteCoupon?subdomain=${subdomain}&code=${code}`,
    validateCoupon: () => `${mobileMarketingParseUrl}/functions/validateCoupon`,
    redeemCoupon: () => `${mobileMarketingParseUrl}/functions/redeemCoupon`,
    validateCouponForUser: () =>
      `${mobileMarketingParseUrl}/functions/validateCouponForUser`,
  },

  POINTS: {
    getPoints: (subdomain, skip, limit) =>
      `${mobileMarketingParseUrl}/functions/usersWallet?skip=${skip}&limit=${limit}&subdomain=${subdomain}`,
    redeemPoints: () =>
      `${mobileMarketingParseUrl}/functions/redeemLoyaltyPoints`,
  },
};
