import { Confirm, DataBlock, Grid } from "components";
import React from "react";
import Notifications from "react-notification-system-redux";
import { I18n, Translate } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
} from "reducers/user";
import { connect } from "routes/routedComponent";
import { PrimaryButton } from "../../../../components";
import classes from "./../Setting.scss";
class Operations extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      displayDelete: false,
      displayCreate: false,
      displayConfirm: false,
      displayConfirm2: false,
    };
  }

  onModalConfirm = () => {
    this.handleCreateButton();
    this.setState({ displayConfirm: false });
  };

  onModalConfirmClear = () => {
    this.handleDeleteButton();
    this.setState({ displayConfirm2: false });
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  cancelConfirm2 = () => {
    this.setState({ displayConfirm2: false });
  };

  generatAPI() {
    const {
      generateAPIKey,
      user: { suit, activeSuitId },
      getCurrentSuit,
    } = this.props;
    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };
    generateAPIKey(data, suit.id).then(() => {
      getCurrentSuit(activeSuitId);
    });
  }

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;
    fillDemoData(activeSuitId).then(() => {
      this.props.showNotification(
        {
          title: "",
          message: I18n.t("ProjectSettingPage_operations_PopulatedMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );
    });
    this.handleCreateDismiss();
  };

  handleCreateDismiss = () => {
    this.setState({ displayCreate: false });
  };

  handleCreateShow = () => {
    this.setState({ displayCreate: true });
  };

  handleDeleteButton = () => {
    const {
      user: { activeSuitId },
      clearDemoData,
    } = this.props;

    clearDemoData(activeSuitId).then(() => {
      this.props.showNotification(
        {
          title: "",
          message: I18n.t("ProjectSettingPage_operations_clearedMsg"),
          position: "bc",
          autoDismiss: 5,
        },
        "success"
      );
    });

    this.handleDismiss();
  };

  handleDeleteShow = () => {
    this.setState({ displayDelete: true });
  };

  handleDismiss = () => {
    this.setState({ show: false, displayDelete: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  showClearMesage = () => {
    const notificationOpts = {
      title: "",
      message: I18n.t("ProjectSettingPage_operations_clearedMsg"),
      position: "bc",
      autoDismiss: 5,
    };
    this.props.dispatch(Notifications.success(notificationOpts));
  };

  showSuccessMesage = () => {
    const notificationOpts = {
      title: "Data Populator Status",
      message: I18n.t("ProjectSettingPage_operations_addedMsg"),
      position: "bc",
      autoDismiss: 5,
    };
    this.props.dispatch(Notifications.success(notificationOpts));
  };

  render() {
    const {
      user: { isPending },
    } = this.props;

    return (
      <div>
        {this.props.user ? (
          <Grid fluid>
            <Confirm
              onConfirm={this.onModalConfirm}
              onClose={this.cancelConfirm}
              display={this.state.displayConfirm}
              title="ProjectSettingPage_operations_populate_title"
              description="ProjectSettingPage_operations_populateNote"
              btnText="ProjectSettingPage_operations_populate_title"
              btnType="primary"
              isPending={isPending}
            />
            <Confirm
              onConfirm={this.onModalConfirmClear}
              onClose={this.cancelConfirm2}
              display={this.state.displayConfirm2}
              title="PopulateData"
              description="ProjectSettingPage_operations_populateNote2"
              btnText="ProjectSettingPage_operations_populate2_btn"
              btnType="critical"
              isPending={isPending}
            />
            {isPending && (
              <div className={classes["loader-container"]}>
                <div className={classes["loader"]}>
                  <i className="fa fa-spinner" aria-hidden="true" />
                </div>
              </div>
            )}
            {this.props.user.suit ? (
              <div>
                {this.props.user.suit.type === "mobile_app" ? (
                  <div className={classes.new}>
                    <DataBlock
                      title="ProjectSettingPage_operations_DataBlock_title"
                      titleClass="bg-grey-lightest"
                    >
                      <h4
                        style={{
                          color: "#030d18",
                          fontSize: "16px",
                          marginLeft: "27px",
                          marginTop: "-30px",
                        }}
                      >
                        {I18n.t(
                          "ProjectSettingPage_operations_DataBlock_title2"
                        )}
                      </h4>
                      <p
                        style={{
                          color: "#7c8c9b",
                          fontSize: "16px",
                          marginLeft: "27px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        {I18n.t("ProjectSettingPage_operations_DataBlock_desc")}
                      </p>
                      <div style={{ marginLeft: "24px", display: "flex" }}>
                        <PrimaryButton
                          onClick={() =>
                            this.setState({ displayConfirm: true })
                          }
                        >
                          {<Translate value="PopulateData" />}
                        </PrimaryButton>
                        <PrimaryButton
                          danger
                          onClick={() =>
                            this.setState({ displayConfirm2: true })
                          }
                        >
                          <Translate value="ProjectSettingPage_operations_populate2_btn" />
                        </PrimaryButton>
                      </div>
                    </DataBlock>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : null}
          </Grid>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapActionCreators = {
  showNotification: Notifications.show,
};
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
    notifications: store.notifications,
  }),
  {
    mapStateToProps,
    generateAPIKey,
    getCurrentSuit,
    getMobileMarketingPlans,
    getMobileAppPlans,
    mapActionCreators,
    fillDemoData,
    clearDemoData,
    showNotification: Notifications.show,
  }
)(Operations);
