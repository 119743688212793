import { Switch } from "antd";
import { Col, Form, Row, TextBox } from "components";
import { minNumLength, required, validcustomDomain } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";
import PrimaryButton from "../../../../../../components/Form/Buttons/PrimaryButton/PrimaryButton";
import classes from "../EmailConfig.scss";

const minLength3 = minNumLength(3);

export class SMTPSEC extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
      requires_authVendors: false,
    };
  }

  componentDidMount() {
    const { getNotifyConfig, config } = this.props;
    getNotifyConfig(this.props.user.activeSuitId).then(() => {
      const { notify } = config;
      if (
        config &&
        notify &&
        notify.channels.email.connection_info.requires_auth
      ) {
        this.handleChange(null, true);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { notify } = nextProps.config;

    if (
      notify &&
      notify.channels.email.connection_info.type === "SMTP" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        vendor: notify.channels.email.vendor || undefined,
        type: notify.channels.sms.connection_info.type || undefined,
        serverURL: notify.channels.email.connection_info.serverURL || undefined,
        security_type:
          notify.channels.email.connection_info.security_type || undefined,
        port: notify.channels.email.connection_info.port || undefined,
        user_name: notify.channels.email.connection_info.user_name || undefined,
        password: notify.channels.email.connection_info.password || undefined,
        requires_auth:
          notify.channels.email.connection_info.requires_auth || undefined,
      });
      this.setState({ initialized: true });
    }
  }

  handleChange(type, value = false) {
    if (value) {
      this.setState({ requires_authVendors: true });
      this.props.change("requires_auth", true);
    }
    if (!value) {
      this.setState({ requires_authVendors: false });
      this.props.change("requires_auth", false);
    }
  }

  render() {
    const { config } = this.props;
    const { notify } = config;

    const { handleSubmit, valid, disabled } = this.props;

    return (
      <Form>
        <div className={classes.contain}>
          <Row>
            <Col md={6}>
              <Field
                name="vendor"
                placeholder={I18n.t("PlatformsPage_vendor_placeholder")}
                validate={required}
                label={I18n.t("name")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>

            <Col md={6}>
              <Field
                name="serverURL"
                placeholder={I18n.t(
                  "PlatformsPage_nav_4_emailOption_3_ServerURL_placeholder"
                )}
                validate={[required, validcustomDomain]}
                label={I18n.t("ServerURL")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="user_name"
                placeholder={I18n.t("PlatformsPage_userName_placeholder")}
                validate={required}
                label={I18n.t("PlatformsPage_userName_label")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
            <Col md={6}>
              <Field
                name="password"
                placeholder={I18n.t("PlatformsPage_password_placeholder")}
                validate={required}
                label={I18n.t("password")}
                component={TextBox}
                type="password"
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Field
                name="security_type"
                placeholder={I18n.t(
                  "PlatformsPage_nav_4_emailOption_3_SecurityType_placeholder"
                )}
                label={I18n.t(
                  "PlatformsPage_nav_4_emailOption_3_SecurityType_label"
                )}
                component={TextBox}
                disabled={disabled}
              />
            </Col>

            <Col md={6}>
              <Field
                name="port"
                type="number"
                placeholder={I18n.t(
                  "PlatformsPage_nav_4_emailOption_3_Port_placeholder"
                )}
                validate={[required, minLength3]}
                label={I18n.t("PlatformsPage_nav_4_emailOption_3_Port_label")}
                component={TextBox}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <div className="form-row toggle-row clearfix">
              <h4
                style={{ color: "black", marginLeft: "17px" }}
                className="pull-left"
              >
                {I18n.t("PlatformsPage_nav_4_emailOption_3_Auth")}
              </h4>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Switch
                disabled={disabled}
                checked={notify.channels.email.connection_info.requires_auth}
                onChange={(checked) => {
                  this.handleChange("type", checked);
                  notify.channels.email.connection_info.requires_auth = checked;
                }}
              />
            </div>
          </Row>

          <PrimaryButton onClick={handleSubmit} disabled={!valid}>
            {I18n.t("saveChangesBtn")}
          </PrimaryButton>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      //
      getNotifyConfig,
    }
  )(SMTPSEC)
);
