import { DataBlock, Grid, Nav, NavItem } from "components";
import React from "react";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import {
  bundleIosId,
  getAppBackendConfig,
  removeCurrentSuit,
  restartApp,
  updateAppBackendConfig,
  uploadIOSFiles,
} from "reducers/user";
import { connect } from "routes/routedComponent";
import EmailConfig from "../../../Push/Config/Email";
import SMSConfig from "../../../Push/Config/SMS";
import WhatsappConfig from "../../../Push/Config/Whatsapp";
import Android from "./Android";
import Ios from "./Ios";
import classes from "./Platforms.scss";

const ANDROID = "android";
const SMS = "sms";
const IOS = "ios";
const EMAIL = "email";
const WHATSAPP = "whatsapp";

class CommunicationChannels extends React.Component {
  constructor() {
    super();
    this.state = {
      componentToRender: ANDROID,
      projectType: null,
    };
  }

  async componentDidMount() {
    const subdomain = window.location.pathname.split("/")[2];
    const {
      getAppBackendConfig,
      user: { activeSuitId, suits, appbackend },
    } = this.props;

    let currentSuit = suits.filter((suit) => suit.id === activeSuitId)[0];

    this.setState({ projectType: currentSuit.type });

    if (!appbackend && currentSuit.type === "mobile_app") {
      await getAppBackendConfig(subdomain, currentSuit.appbackend_userId);
    }

    if (currentSuit.type !== "mobile_app") {
      this.setState({ componentToRender: SMS });
    }
  }

  handleClick = (componentName) => {
    this.setState({ componentToRender: componentName });
  };

  render() {
    const { componentToRender, projectType } = this.state;

    return (
      <div className={classes.wholecomponent}>
        <Grid fluid>
          <div className={`${classes.new} new`} style={{ marginLeft: "-36px" }}>
            <DataBlock
              title="CommunicationChannels"
              titleClass="bg-grey-lightest"
            ></DataBlock>
          </div>
          <div className={classes.navigationbar}>
            <Nav className={classes.nav}>
              {projectType !== "mobile_marketing" && (
                <NavItem
                  className={
                    componentToRender === ANDROID ? classes.val : classes.val2
                  }
                  onClick={() => this.handleClick(ANDROID)}
                >
                  {I18n.t("AndroidPush")}
                </NavItem>
              )}
              {projectType !== "mobile_marketing" && (
                <NavItem
                  className={
                    componentToRender === IOS ? classes.val : classes.val2
                  }
                  onClick={() => this.handleClick(IOS)}
                >
                  {I18n.t("IOSPush")}
                </NavItem>
              )}
              <NavItem
                className={
                  componentToRender === SMS ? classes.val : classes.val2
                }
                onClick={() => this.handleClick(SMS)}
              >
                {I18n.t("SMS")}
              </NavItem>
              <NavItem
                className={
                  componentToRender === EMAIL ? classes.val : classes.val2
                }
                onClick={() => this.handleClick(EMAIL)}
              >
                {I18n.t("Email")}
              </NavItem>
              <NavItem
                className={
                  componentToRender === WHATSAPP ? classes.val : classes.val2
                }
                onClick={() => this.handleClick(WHATSAPP)}
              >
                {I18n.t("Whatsapp")}
              </NavItem>
            </Nav>
          </div>
          {componentToRender === ANDROID && <Android />}
          {componentToRender === IOS && <Ios />}
          {componentToRender === SMS && <SMSConfig />}
          {componentToRender === EMAIL && <EmailConfig />}
          {componentToRender === WHATSAPP && <WhatsappConfig />}
        </Grid>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
    form: store.form,
    config: store.config,
  }),
  {
    getAppBackendConfig,
    bundleIosId,
    uploadIOSFiles,
    updateAppBackendConfig,
    removeCurrentSuit,
    restartApp,
    setNotifyConfig,
    getNotifyConfig,
  }
)(CommunicationChannels);
