import DigitalInvoice from "api/DigitalInvoice";
import UserScreen from "api/UserScreen";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------

const GET_MOBILE_USER_INFO = "GET_MOBILE_USER_INFO";
const GET_MOBILE_USER_DIGITAL_INVOICES = "GET_MOBILE_USER_DIGITAL_InVOICES";
const MOBILE_USER_DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW =
  "MOBILE_USER_DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW";
const MOBILE_USER_DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS =
  "MOBILE_USER_DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS";
const MOBILE_USER_DIGITAL_INVOICES_ON_COLUMN_TOGGLE =
  "MOBILE_USER_DIGITAL_INVOICES_ON_COLUMN_TOGGLE";
const MOBILE_USER_DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU =
  "MOBILE_USER_DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU";
const MOBILE_USER_DIGITAL_INVOICES_CLEAR_ACTION_MENU =
  "MOBILE_USER_DIGITAL_INVOICES_CLEAR_ACTION_MENU";
const MOBILE_USER_DIGITAL_INVOICES_ON_RESET_COLUMNS =
  "MOBILE_USER_DIGITAL_INVOICES_ON_RESET_COLUMNS";
const MOBILE_USER_SORT_DIGITAL_INVOICES = "MOBILE_USER_SORT_DIGITAL_INVOICES";

// ------------------------------------
// Actions
// ------------------------------------

export function getMobileUserInfo(subdomain, userId) {
  return {
    type: GET_MOBILE_USER_INFO,
    payload: UserScreen.getMobileUserInfo(subdomain, userId),
  };
}

export function getMobileUserDigitalInvoices(subdomain, userId) {
  return {
    type: GET_MOBILE_USER_DIGITAL_INVOICES,
    payload: DigitalInvoice.getMobileUserDigitalInvoices(subdomain, userId),
  };
}

// Data table reducer functions
export function checkDataTableRow(idx) {
  return {
    type: MOBILE_USER_DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: MOBILE_USER_DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS,
    payload: newChecked,
  };
}

export function onColumnToggle(idx) {
  return {
    type: MOBILE_USER_DIGITAL_INVOICES_ON_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function toggleRowActionMenu(idx) {
  return {
    type: MOBILE_USER_DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: MOBILE_USER_DIGITAL_INVOICES_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: MOBILE_USER_DIGITAL_INVOICES_ON_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: MOBILE_USER_SORT_DIGITAL_INVOICES,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  // mobile user info
  mobileUserInfoPending: false,
  mobileUserInfoError: false,

  // mobile user info
  mobileUserDigitalInvoicesPending: false,
  mobileUserDigitalInvoicesError: false,
  mobileUserDigitalInvoicesTableData: null,
  mobileUserDigitalInvoices: [],
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function MobileUserInfo(state = initialState, action) {
  switch (action.type) {
    // mobile user info
    case `${GET_MOBILE_USER_INFO}_PENDING`:
      return {
        ...state,
        mobileUserInfoPending: true,
      };

    case `${GET_MOBILE_USER_INFO}_REJECTED`:
      return {
        ...state,
        mobileUserInfoPending: false,
        mobileUserInfoError: true,
      };
    case `${GET_MOBILE_USER_INFO}_FULFILLED`:
      return {
        ...state,
        mobileUserInfoPending: false,
        mobileUserInfoError: false,
      };

    // mobile user digital invoices
    case `${GET_MOBILE_USER_DIGITAL_INVOICES}_PENDING`:
      return {
        ...state,
        mobileUserDigitalInvoicesPending: true,
      };

    case `${GET_MOBILE_USER_DIGITAL_INVOICES}_REJECTED`:
      return {
        ...state,
        mobileUserDigitalInvoicesPending: false,
        mobileUserDigitalInvoicesError: true,
      };
    case `${GET_MOBILE_USER_DIGITAL_INVOICES}_FULFILLED`:
      return {
        ...state,
        mobileUserDigitalInvoicesPending: false,
        mobileUserDigitalInvoicesError: false,
        mobileUserDigitalInvoices: action.payload.body.results,
        mobileUserDigitalInvoicesTableData: prepareTableData(
          action.payload.body.results
        ),
      };

    // data table
    case MOBILE_USER_DIGITAL_INVOICES_CHECK_DATA_TABLE_ROW:
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          rows: state.mobileUserDigitalInvoicesTableData.rows.map(
            (row, rowIdx) => {
              return rowIdx === action.payload
                ? { ...row, checked: !row.checked }
                : row;
            }
          ),
        },
      };
    case MOBILE_USER_DIGITAL_INVOICES_CHECK_ALL_DATA_TABLE_ROWS:
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          rows: state.mobileUserDigitalInvoicesTableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case MOBILE_USER_DIGITAL_INVOICES_ON_COLUMN_TOGGLE:
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          labels: state.mobileUserDigitalInvoicesTableData.labels.map(
            (label, idx) => {
              return idx === action.payload
                ? {
                    ...label,
                    selected: !label.selected,
                  }
                : label;
            }
          ),
        },
      };
    case MOBILE_USER_DIGITAL_INVOICES_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          rows: state.mobileUserDigitalInvoicesTableData.rows.map(
            (row, rowIdx) => {
              return rowIdx === action.payload
                ? { ...row, actionsMenu: !row.actionsMenu }
                : row;
            }
          ),
        },
      };
    case MOBILE_USER_DIGITAL_INVOICES_CLEAR_ACTION_MENU:
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          rows: state.mobileUserDigitalInvoicesTableData.rows.map(
            (row, idx) => {
              return idx === action.payload
                ? { ...row, actionsMenu: false }
                : row;
            }
          ),
        },
      };
    case MOBILE_USER_DIGITAL_INVOICES_ON_RESET_COLUMNS:
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          labels: state.mobileUserDigitalInvoicesTableData.labels.map(
            (label) => ({
              ...label,
              selected: true,
            })
          ),
        },
      };
    case MOBILE_USER_SORT_DIGITAL_INVOICES: {
      const indexOfKey = state.mobileUserDigitalInvoicesTableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.mobileUserDigitalInvoicesTableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(
            state.mobileUserDigitalInvoicesTableData.rows,
            (row) => row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(
            state.mobileUserDigitalInvoicesTableData.rows,
            (row) => parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(
            state.mobileUserDigitalInvoicesTableData.rows,
            (row) => new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        mobileUserDigitalInvoicesTableData: {
          ...state.mobileUserDigitalInvoicesTableData,
          rows: tableRows,
        },
      };
    }
    default:
      return state;
  }
}

export const prepareTableData = (tableData) => {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "USER ID",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "userId",
      defaultValue: "N/A",
    },
    {
      name: "INVOICE NUMBER",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "invoice_number",
      defaultValue: "N/A",
    },
    {
      name: "TOTAL",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: "total",
      defaultValue: "N/A",
    },
    {
      name: "TO",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "N/A",
    },

    {
      name: "MOBILE NUMBER",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "phone",
      defaultValue: "N/A",
    },
    {
      name: "EMAIL",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "email",
      defaultValue: "N/A",
    },
    {
      name: "CREATED AT",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "updatedAt",
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => {
    return {
      checked: false,
      actionsMenu: false,
      id: row.name,
      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows, actions: true };
};
